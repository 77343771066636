import ContainerComp from '@mui/material/Container'
import styled from 'styled-components'

import { theme } from 'styles/theme'

export const Container = styled(ContainerComp)({
  background: theme.colors.white,
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '10px',
})
