import { useLocation } from 'react-router-dom'
import ModeSelection from 'components/organisms/mode-selection'
import useAccessData from 'hooks/use-access-data'
import * as S from './styled'

const View = () => {
  const { getData } = useAccessData()
  const { state } = useLocation()

  const { dni, token, lng } = getData()

  return (
    <S.Container>
      <ModeSelection defaultValue={state?.value} userId={dni} token={token} />
    </S.Container>
  )
}

export default View
