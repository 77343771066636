import { useCallback, useMemo, useRef, useEffect } from 'react'
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from '@mui/x-data-grid'
import { Box, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { SupplyPointsModel, SupplyPointsToSave } from 'model/interfaces/supply-points'
import HistoricalItemModel from 'model/interfaces/historical'
import { formatMoney } from 'utils/numbers'
import Cells from 'components/molecules/cells/view'
import Headers from 'components/molecules/headers/view'
import BannerInfo from 'components/atoms/banner-info'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'

import { theme } from 'styles/theme'

import {
  ADDRESS_COLUMN_WIDTH,
  QUANTITY_COLUMN_WIDTH,
  SUPPLY_COLUMN_WIDTH,
  SUPPLY_ACTIVE_COLUMN_WIDTH,
} from './utils/constants'

import {
  StyledDataGrid,
  AutoTypography,
  NotAutoTypography,
  BContainer,
  FBox,
  BoldTypography,
  OrangeTypography,
  ButtonBox,
  SContainer,
  AContainer,
  autoBox,
  btnBlue,
  btnOrange,
} from './styled'

import Field from './field'
import Button from 'components/atoms/button'

type Props = {
  supplyPoints: SupplyPointsModel[]
  total?: number
  onSave: (supplyPointsList: SupplyPointsToSave[]) => void
  onCancel: () => void
  isSaving: boolean
  errors: string[]
}

const SupplyPointsView = ({ supplyPoints, total, onSave, onCancel, isSaving, errors }: Props) => {
  const { t } = useTranslation()

  const supplyImports = useRef<any>({})

  useEffect(() => {
    if (supplyImports.current) {
      supplyPoints.forEach(item => {
        supplyImports.current[item.supply] = {
          supply: item.supply,
          quantity: item.quantity?.toString().replace(',', '.') || '0',
          enabled: false,
        }
      })
    }
  }, [supplyPoints])

  const onChangeSupplyImports = useCallback((event: any, params: GridRenderCellParams) => {
    const value = event.target.value

    const element = { supply: params.row.supply, quantity: value.replace(',', '.') || '0', enabled: false }
    supplyImports.current[params.row.supply] = element
  }, [])

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'address',
        sortable: false,
        minWidth: ADDRESS_COLUMN_WIDTH,
        renderCell: props => (
          <Cells
            tooltip={props?.value?.toString()}
            params={props}
            title={t`addressTable`}
            boxSx={{ justifyContent: 'start' }}
          />
        ),
        flex: 1.5,
        headerAlign: 'center',
        align: 'left',
      },
      {
        field: 'supply_active',
        sortable: false,
        minWidth: SUPPLY_ACTIVE_COLUMN_WIDTH,
        renderCell: props => <Cells params={props} title={t`supplyActive`} boxSx={{ justifyContent: 'center' }} />,
        flex: 0.5,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'is_self_consumption',
        sortable: false,
        minWidth: SUPPLY_COLUMN_WIDTH,
        renderCell: props => (
          <Box>
            {props.value ? (
              <AutoTypography variant='body1'>{t`auto`}</AutoTypography>
            ) : (
              <NotAutoTypography variant='body1'>{t`notAuto`}</NotAutoTypography>
            )}
          </Box>
        ),
        flex: 1,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'quantity',
        sortable: false,
        minWidth: QUANTITY_COLUMN_WIDTH,
        renderCell: props => <Field params={props} onChangeImport={onChangeSupplyImports} />,
        renderHeader: (props: GridColumnHeaderParams<any, HistoricalItemModel, any>) => <Headers params={props} />,
        headerAlign: 'center',
        align: 'right',
        flex: 0.5,
      },
    ],
    [onChangeSupplyImports, t]
  )

  const onSaveSupplyPoints = useCallback(() => {
    const response = Object.keys(supplyImports.current).map(key => {
      const { supply, quantity, enabled } = supplyImports.current[key]
      return { supply, quantity, enabled }
    })
    onSave(response)
  }, [onSave])

  return (
    <>
      <SContainer>
        <BContainer>
          <FBox>
            <BoldTypography variant='h6'>{t(`available`)}</BoldTypography>
            <OrangeTypography variant='h6'>{formatMoney(total?.toString() || '0', false, false)}</OrangeTypography>
          </FBox>

          <Box component='div' sx={autoBox}>
            <StyledDataGrid
              columnHeaderHeight={0}
              getRowId={row => row.id}
              rows={supplyPoints}
              columns={columns}
              disableColumnMenu
              disableRowSelectionOnClick
              pagination
              paginationMode='server'
              rowCount={10000}
              rowHeight={80}
              slots={{
                noResultsOverlay: () => (
                  <Stack height='100%' alignItems='center' justifyContent='center'>
                    {t`noSupplyResultsReason`}
                  </Stack>
                ),
              }}
            />
          </Box>
        </BContainer>
        <AContainer>
          {errors?.length > 0 && (
            <BannerInfo
              align='center'
              iconLeft={<WarningAmberRoundedIcon style={{ color: theme.colors.blue }} />}
              bgColor={theme.colors.warning}
            >
              {errors[0]}
            </BannerInfo>
          )}
        </AContainer>
        <ButtonSection onSave={onSaveSupplyPoints} onCancel={onCancel} isSaving={isSaving} />
      </SContainer>
    </>
  )
}

type ButtonProps = {
  onSave: () => void
  onCancel: () => void
  isSaving: boolean
}

const ButtonSection = ({ onSave, onCancel, isSaving }: ButtonProps) => {
  const { t } = useTranslation()
  return (
    <ButtonBox>
      <Button color='blue' variant='outlined' onClick={onCancel} sx={btnBlue}>
        {t`dischargeBtn`}
      </Button>
      <Button color='orange' onClick={onSave} sx={btnOrange} isLoading={isSaving}>
        {t`save`}
      </Button>
    </ButtonBox>
  )
}

export default SupplyPointsView
