export const postIframeHeightEvent = (isFetching: boolean): void => {
  if (isFetching) {
    window.parent.postMessage(
      {
        id: 'facint_iframe',
        height: 650,
        width: document?.getElementById('default-layout-container')?.scrollWidth,
      },
      '*'
    )
  } else {
    window.parent.postMessage(
      {
        id: 'facint_iframe',
        height: document?.getElementById('default-layout-last-item')?.offsetTop || 0,
        width: document?.getElementById('default-layout-container')?.scrollWidth,
      },
      '*'
    )
  }
}

export const getUrlParam = (param: string): string | null => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  return urlParams.get(param)
}
