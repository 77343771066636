import { styled, css } from '@mui/material/styles'

import { theme } from 'styles/theme'
import { Typography } from '@mui/material'

export const RoundVariant = css`
  margin-top: 10px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  color: ${theme.colors.white};
  font-weight: 600,
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 25px;
  width: 120px;
  height: 30px;
  font-size: 12px;
  margin-bottom: 10px;
`

export const SquareVariant = css`
  margin-top: 10px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  color: ${theme.colors.blue};
  font-weight: 600;
  padding: 8px;
  border-radius: 4px;
  background: ${theme.colors.blueLight};
  font-size: 14px;
  margin-bottom: 10px;
`

type AutoTypographyProps = {
  styleVariant?: string
}

export const AutoTypography = styled(Typography)`
  &&& {
    ${({ styleVariant }: AutoTypographyProps) => {
      switch (styleVariant) {
        case 'square':
          return SquareVariant
        default:
          return RoundVariant
      }
    }}
  }
`
