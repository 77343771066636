import { MouseEventHandler } from 'react'
import styled from 'styled-components'
import { theme } from 'styles/theme'

export const defaultColor = theme.colors.orange

interface IconContainerProps {
  color: string
  width: string
  height: string
  top: string
  left: string
  onClick?: MouseEventHandler<HTMLDivElement>
}

export const IconContainer = styled.div<IconContainerProps>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  position: relative;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  cursor: ${({ onClick }) => onClick && 'pointer'};

  svg {
    height: ${({ height }) => height};
    width: ${({ width }) => width};
    color: ${({ color }) => color};
  }

  &:hover {
    .tooltip {
      display: block;
    }
  }
`

export const Tooltip = styled.div`
  display: none;
  background-color: #ffffff;
  border: 1px solid ${theme.colors.black};
  padding: 10px;
  text-transform: none;
  position: absolute;
  right: -100px;
  top: 25px;
  width: 320px;
  z-index: 9;
  border-radius: 5px;
`
