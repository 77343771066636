import { useEffect, useState } from 'react'
import useTheme from '@mui/material/styles/useTheme'

const useAccessData = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const { breakpoints } = useTheme()
  const getSize = () => {
    if (width <= breakpoints?.values.xs) {
      return 'xs'
    }
    if (width <= breakpoints?.values.sm) {
      return 'sm'
    }
    if (width <= breakpoints?.values.md) {
      return 'md'
    }
    if (width <= breakpoints?.values.lg) {
      return 'lg'
    }
    if (width <= breakpoints?.values.xl) {
      return 'xl'
    }
  }
  useEffect(() => {
    setWidth(window.innerWidth)
    addEventListener('resize', () => {
      setWidth(window.innerWidth)
    })
  }, [])
  return { width, size: getSize() }
}

export default useAccessData
