import { useTranslation } from 'react-i18next'
import ModeBarComp from 'components/molecules/button-block'
import * as S from './styled'

type Props = {
  onClickChange: () => void
  isAutomatic: boolean
  onClickEdit: () => void
  isLoading: boolean
}

const View = ({ onClickChange, isAutomatic, onClickEdit, isLoading }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <S.Container>
        <ModeBarComp
          onClick={onClickChange}
          title={t('modeBar.title')}
          subtitle={isAutomatic ? t('automatic') : t('manual')}
          buttonText={t('modeBar.change')}
          isLoading={isLoading}
        />
        <ModeBarComp
          isLoading={isLoading}
          onClick={onClickEdit}
          title={isAutomatic ? t('editModeBar.title') : t('editModeBar.titleM')}
          buttonText={isAutomatic ? t('editModeBar.edit') : t('editModeBar.editM')}
        />
      </S.Container>
    </>
  )
}

export default View
