import { styled } from '@mui/material/styles'
import ViewComp from './view'

import { theme } from 'styles/theme'

export const View = styled(ViewComp)`
  && > div {
    color: ${theme.colors.blue};
    background-color: ${theme.colors.white};
    box-shadow: 2px 2px 4px ${theme.colors.blueLight};
  }

  && .MuiTooltip-arrow {
    color: ${theme.colors.white};
  }
`
