import styled from 'styled-components/macro'
import { theme } from 'styles/theme'

export const ErrorPageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 80px 15px 0;
`

export const ErrorPageWrapper = styled.div`
  width: 100%;
  padding: 100px 0px;
  justify-content: center;
`

export const Title = styled.h1`
  margin-bottom: 24px;
  margin-top: 24px;
  text-align: center;
  font-size: 48px;
  font-weight: normal;
  font-style: normal;
  line-height: 53px;
  color: #004571;
  font-family: 'FS Emeric';
`

export const Subtitle = styled.p`
  text-align: center;
  color: #004571;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 17px;
  font-family: 'FS Emeric';
`

export const FirstRecommendation = styled.p`
  font-size: 24px;
  line-height: 37px;
`

export const SecondRecommendation = styled.p`
  margin: 25px 0;
  text-align: center;
  color: #004571;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 30px;
  font-family: 'FS Emeric';
`

export const TitleContainer = styled.div``

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 40px;

  > span {
    color: #004571;
    font-size: 26px;
    font-family: 'FS Emeric';
  }
  > a {
    color: #004571;
    font-size: 26px;
    font-family: 'FS Emeric';
    /* font-weight: 200; */
  }
`

export const GoHomeButton = styled.button`
  display: flex;

  & .icon-container svg {
    transform: rotateZ(180deg);
    width: 16px;
    height: 16px;
  }
  /*
  @media (max-width: ${theme.breakpoints.large}px) {
    justify-content: center;
  }
  */
`
