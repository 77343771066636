import { Typography } from '@mui/material'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  .start-btn {
    margin-right: 24px;
  }
`

export const PagesContainer = styled.div`
  display: flex;
  gap: 16px;
`

export const pagesStyle = { fontSize: 14, cursor: 'pointer' }
export const pagesStyleBold = { fontSize: 14, fontWeight: 600 }
