import { Route, Routes } from 'react-router-dom'
import 'assets/i18n'
import Error from 'components/pages/errorPage'
import Layout from 'components/system/layout'

import Mode from 'components/pages/mode'

import Home from 'components/pages/home/view'
import Access from 'components/pages/access'
import SupplyPoints from 'components/pages/supply-points'

const AppRoutes = () => {
  return (
    <div id='default-layout-container'>
      <Routes>
        <Route path='/access' element={<Layout />}>
          <Route path='home' element={<Home />} />
          <Route path=':token' element={<Access />} />
          <Route path='error' element={<Error />} />
          <Route path='assign/:type' element={<SupplyPoints />} />
          <Route path='mode' element={<Mode />} />
        </Route>
        <Route path='*' element={<Error />} />
      </Routes>
      <div id='default-layout-last-item' />
    </div>
  )
}

export default AppRoutes
