import * as React from 'react'
import Radio from '@mui/material/Radio'
import FormLabel from '@mui/material/FormLabel'
import * as Types from './types'
import * as S from './styled'

const View = ({ value, options, label, name, onChange, disabled }: Types.PropsView) => {
  return (
    <S.Container>
      {label && <FormLabel>{label}</FormLabel>}
      <S.RadioGroup value={value} name={name} onChange={onChange}>
        {options?.map(({ value, label }: Types.Option) => (
          <S.FormControlLabel
            key={value.toString()}
            disabled={disabled}
            value={value}
            control={<Radio />}
            label={label}
          />
        ))}
      </S.RadioGroup>
    </S.Container>
  )
}

export default View
