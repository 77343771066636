import { ReactNode, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { TextField } from '@mui/material'
import Tooltip from 'components/atoms/tooltip'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'

import { SupplyPointsModel, SupplyPointsToSave } from 'model/interfaces/supply-points'
import Button from 'components/atoms/button'
import BannerInfo from 'components/atoms/banner-info'
import { theme } from 'styles/theme'

import * as S from './styled'

import { formatMoney } from 'utils/numbers'

type Props = {
  supplyPoints: SupplyPointsModel[]
  total?: number
  onSave: (supplyPointsList: SupplyPointsToSave[]) => void
  onCancel: () => void
  isSaving: boolean
  errors: string[]
}

type ItemFieldProps = {
  children: ReactNode
  label: string
}

const ItemField = ({ children, label }: ItemFieldProps) => (
  <S.ItemField>
    <S.Label>{label}</S.Label>
    {children}
  </S.ItemField>
)

const SupplyManual = ({ supplyPoints, total, onSave, onCancel, isSaving, errors }: Props) => {
  const { t } = useTranslation()

  const supplyImports = useRef<any>({})

  const onChangeSupplyImports = useCallback((event: any, item: SupplyPointsModel) => {
    const value = event.target.value

    const element = { supply: item.supply, quantity: value.replace(',', '.') || '0', enabled: false }
    supplyImports.current[item.supply] = element
  }, [])

  const onSaveSupplyPoints = useCallback(() => {
    const response = Object.keys(supplyImports.current).map(key => {
      const { supply, quantity, enabled } = supplyImports.current[key]
      return { supply, quantity, enabled }
    })
    onSave(response)
  }, [onSave])

  return (
    <S.BContainer>
      <S.TitleRow>
        <S.Title variant='h6'>{t`available`}</S.Title>
        <S.Total variant='h6'>{formatMoney(total?.toString())}</S.Total>
      </S.TitleRow>
      {supplyPoints?.map(item => {
        return (
          <S.Item key={item.supply}>
            <S.FRow>
              <ItemField label={t`address`}>
                <Tooltip title={item.address}>
                  <S.AddressText>{item.address}</S.AddressText>
                </Tooltip>
              </ItemField>
            </S.FRow>
            <S.SRow>
              {item.is_self_consumption ? (
                <S.AutoChip isAuto>{t`auto`}</S.AutoChip>
              ) : (
                <S.AutoChip>{t`notAuto`}</S.AutoChip>
              )}
              <ItemField label={t`supplyActive`}>
                <S.Text>{item.supply_active ? t`active` : t`inactive`}</S.Text>
              </ItemField>
            </S.SRow>
            <S.TRow>
              <Field item={item} onChangeImport={onChangeSupplyImports} />
            </S.TRow>
          </S.Item>
        )
      })}
      {errors?.length > 0 && (
        <BannerInfo
          align='center'
          iconLeft={<WarningAmberRoundedIcon style={{ color: theme.colors.blue }} />}
          bgColor={theme.colors.warning}
        >
          {errors[0]}
        </BannerInfo>
      )}
      <ButtonSection onSave={onSaveSupplyPoints} onCancel={onCancel} isSaving={isSaving} />
    </S.BContainer>
  )
}

type ButtonProps = {
  onSave: () => void
  onCancel: () => void
  isSaving: boolean
}

const ButtonSection = ({ onSave, onCancel, isSaving = false }: ButtonProps) => {
  const { t } = useTranslation()
  return (
    <S.ButtonBox>
      <Button color='blue' variant='outlined' onClick={onCancel} sx={S.buttonStyle}>
        {t`cancel`}
      </Button>
      <Button color='orange' onClick={onSave} sx={S.buttonStyle} isLoading={isSaving}>
        {t`save`}
      </Button>
    </S.ButtonBox>
  )
}

type FieldProps = {
  item: SupplyPointsModel
  onChangeImport: (event: any, item: SupplyPointsModel) => void
}

const Field = ({ item, onChangeImport }: FieldProps) => {
  const { t } = useTranslation()

  const onChange = (values: any) => {
    console.log('change')
  }

  return (
    <NumericFormat
      customInput={TextField}
      onValueChange={values => onChange(values)}
      id='sText'
      label={t('quantitityDischarge')}
      defaultValue={formatMoney(item.quantity.toString())}
      decimalSeparator=','
      variant='standard'
      inputProps={{ style: { fontWeight: 600, fontSize: 14 } }}
      sx={S.txtSx}
      onChange={event => onChangeImport(event, item)}
    />
  )
}

export default SupplyManual
