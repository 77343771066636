import { formatDate } from 'utils/dates'

export const getDateInfo = () => {
  const now = new Date()
  const year = now.getFullYear()
  const today = formatDate(now)
  const previousMonth = new Date(now.getTime())
  previousMonth.setDate(0)
  const toMonth = formatDate(previousMonth)
  const fromMonth = `01/${now.getMonth().toString().padStart(2, '0')}/${year}`
  return { today, toMonth, fromMonth, year }
}
