import { styled } from '@mui/material/styles'
import RadioGroupComp from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import FormControlLabelComp from '@mui/material/FormControlLabel'

import { theme } from 'styles/theme'

export const Container = styled(FormControl)`
  && {
  }
`

export const RadioGroup = styled(RadioGroupComp)``

export const FormControlLabel = styled(FormControlLabelComp)`
  && {
    padding-bottom: 23px;
    padding-top: 23px;
    border-bottom: solid 1px ${theme.colors.blueLight};
    align-items: flex-start;
    &:last-child {
      border-bottom: 0px;
    }
  }
`
