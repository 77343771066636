import { useCallback, useMemo, useState, useEffect } from 'react'
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Skeleton from '@mui/material/Skeleton'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'

import { SupplyPointsModel, SupplyPointsToSave } from 'model/interfaces/supply-points'
import CheckCell from 'components/molecules/check-cell/view'
import Cells from 'components/molecules/cells/view'
import Button from 'components/atoms/button'
import BannerInfo from 'components/atoms/banner-info'

import { theme } from 'styles/theme'

import {
  ADDRESS_COLUMN_WIDTH,
  ENABLE_COLUMN_WIDTH,
  SUPPLY_COLUMN_WIDTH,
  SUPPLY_ACTIVE_COLUMN_WIDTH,
} from './utils/constants'

import {
  AutomaticStyledDataGrid,
  AutoTypography,
  NotAutoTypography,
  AContainer,
  ButtonBox,
  SContainer,
  buttonStyle,
  skelleton,
  skelletonItem,
  addSkel,
  btnSkel,
  autoBox,
} from './styled'

type Props = {
  supplyPoints: SupplyPointsModel[]
  total?: number
  onSave: (supplyPointsList: SupplyPointsToSave[]) => void
  onCancel: () => void
  isFetching: boolean
  isSaving: boolean
  errors: string[]
}

type Item = {
  supply: string
  enabled: boolean
  quantity: string
}

const AutomaticSupplyPointsView = ({ supplyPoints, onSave, onCancel, isFetching, isSaving }: Props) => {
  const { t } = useTranslation()

  const [enableSupplyPoints, setEnableSupplyPoints] = useState<any>([])

  useEffect(() => {
    setEnableSupplyPoints(supplyPoints)
  }, [supplyPoints])

  const onChangeCheck = useCallback(
    (event: any, params: any, value: boolean) => {
      const newItemIndex = enableSupplyPoints.findIndex((item: any) => item.supply === params.row.supply)

      const newEnableSupplyPoints = [...enableSupplyPoints]
      const newItem = { ...newEnableSupplyPoints[newItemIndex] }
      newItem.enabled = value
      newEnableSupplyPoints[newItemIndex] = newItem

      setEnableSupplyPoints(newEnableSupplyPoints)
    },
    [enableSupplyPoints]
  )

  const onSaveSupplyPoints = useCallback(() => {
    const response = enableSupplyPoints.map((element: Item) => {
      return { supply: element.supply, quantity: '0.00', enabled: element.enabled }
    })
    onSave(response)
  }, [onSave, enableSupplyPoints])

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'enabled',
        sortable: false,
        minWidth: ENABLE_COLUMN_WIDTH,
        renderCell: props => (
          <CheckCell params={props} boxSx={{ justifyContent: 'start' }} onChangeCheck={onChangeCheck} />
        ),
        align: 'left',
        flex: 0.3,
      },
      {
        field: 'address',
        sortable: false,
        minWidth: ADDRESS_COLUMN_WIDTH,
        renderCell: props => (
          <Cells tooltip={props?.value?.toString()} params={props} boxSx={{ justifyContent: 'start' }} />
        ),
        flex: 1,
        headerAlign: 'center',
        align: 'left',
      },

      {
        field: 'supply_active',
        sortable: false,
        minWidth: SUPPLY_ACTIVE_COLUMN_WIDTH,
        renderCell: props => <Cells params={props} title={t`supplyActive`} boxSx={{ justifyContent: 'center' }} />,
        flex: 0.5,
        headerAlign: 'center',
        align: 'center',
      },

      {
        field: 'is_self_consumption',
        sortable: false,
        minWidth: SUPPLY_COLUMN_WIDTH,
        renderCell: (props: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => (
          <Box>
            {props.value ? (
              <AutoTypography variant='body1'>{t`auto`}</AutoTypography>
            ) : (
              <NotAutoTypography variant='body1'>{t`notAuto`}</NotAutoTypography>
            )}
          </Box>
        ),
        flex: 0.5,
        headerAlign: 'center',
        align: 'left',
      },
    ],
    [onChangeCheck, t]
  )

  return (
    <>
      <SContainer>
        <AContainer>
          <Box component='div' sx={autoBox}>
            <AutomaticStyledDataGrid
              columnHeaderHeight={0}
              getRowId={row => row.id}
              rows={enableSupplyPoints}
              columns={columns}
              disableColumnMenu
              loading={isFetching || enableSupplyPoints?.length === 0}
              disableRowSelectionOnClick
              pagination
              paginationMode='server'
              rowCount={10000}
              autoHeight
              rowHeight={80}
              slots={{
                noResultsOverlay: () => <SkeletonLoading />,
                loadingOverlay: () => (enableSupplyPoints?.length === 0 ? <SkeletonLoading /> : null),
              }}
            />
          </Box>
        </AContainer>
        <AContainer>
          {!enableSupplyPoints.find((item: any) => item.enabled) && (
            <BannerInfo
              align='center'
              iconLeft={<WarningAmberRoundedIcon style={{ color: theme.colors.blue }} />}
              bgColor={theme.colors.warning}
            >
              {t('warningAutomaticForm')}
            </BannerInfo>
          )}
        </AContainer>
        <ButtonSection
          onSave={onSaveSupplyPoints}
          onCancel={onCancel}
          isSaving={isSaving}
          hasErrors={!enableSupplyPoints.find((item: any) => item.enabled)}
        />
      </SContainer>
    </>
  )
}

type ButtonProps = {
  onSave: () => void
  onCancel: () => void
  isSaving: boolean
  hasErrors?: boolean
}

const ButtonSection = ({ onSave, onCancel, isSaving = false, hasErrors }: ButtonProps) => {
  const { t } = useTranslation()
  return (
    <ButtonBox>
      <Button color='blue' variant='outlined' onClick={onCancel} sx={buttonStyle}>
        {t`cancel`}
      </Button>
      <Button color='orange' onClick={onSave} sx={buttonStyle} isLoading={isSaving} disabled={hasErrors}>
        {t`end`}
      </Button>
    </ButtonBox>
  )
}

const SkeletonLoading = () => {
  return (
    <Box sx={skelleton}>
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
    </Box>
  )
}

const SkeletonItem = () => {
  return (
    <Box height={80} sx={skelletonItem}>
      <Skeleton animation='wave' variant='rounded' width={400} height={30} />
      <Skeleton animation='wave' variant='rounded' width={100} height={30} sx={addSkel} />
      <Skeleton animation='wave' variant='rounded' width={150} height={30} sx={btnSkel} />
    </Box>
  )
}

export default AutomaticSupplyPointsView
