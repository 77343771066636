import { useState } from 'react'
import { ClickAwayListener } from '@mui/material'
import useBreackpoint from 'hooks/use-breackpoint'
import { View } from './styled'

interface Props {
  children: React.ReactElement
  title?: string | React.ReactNode
}

const Tooltip = ({ children, title }: Props) => {
  const [open, setOpen] = useState(false)
  const { size } = useBreackpoint()

  const handleClose = () => {
    console.log('SALE')
    setOpen(false)
  }

  const handleClick = () => {
    console.log('ENTRA')
    setOpen(true)
  }

  const isSm = size === 'sm' || size === 'xs' || size === 'md'

  console.log('HOLAS', isSm)

  if (isSm) {
    return (
      <View
        onClose={handleClose}
        title={title}
        disableFocusListener={true}
        disableHoverListener={true}
        disableTouchListener={true}
        open={open}
        isSm
      >
        <div onClick={handleClick}>{children}</div>
      </View>
    )
  }
  return (
    <View onClose={handleClose} title={title}>
      {children}
    </View>
  )
}
export default Tooltip
