import { useState } from 'react'
import View from './view'
import useVirtualBattery from 'hooks/use-battery'

type Props = {
  userId: string
  token: string
}

const Historical = ({ userId, token, ...props }: Props) => {
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }
  const { battery } = useVirtualBattery(userId, token)
  return (
    <View
      {...props}
      battery={battery}
      userId={userId}
      token={token}
      open={open}
      onClose={handleTooltipClose}
      onOpen={handleTooltipOpen}
    />
  )
}
export default Historical
