import { useMemo } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import BatteryModel from 'model/interfaces/battery'
import Historical from 'components/organisms/historical'
import ModeBar from 'components/organisms/mode-bar'
import Title from 'components/molecules/title'

import { formatMoney } from 'utils/numbers'
//import { formatDate } from 'utils/dates'

import { getDateInfo } from './utils'
import {
  BContainer,
  AvailableBox,
  CenterBox,
  CenterTypography,
  OrangeTypography,
  borderStyle,
  marginStyle,
  AvailableCenterBox,
  CBox,
  BoldTypography,
  AvailableBoxGrey,
} from './styled'

type Props = {
  userId: string
  battery: BatteryModel
  token: string
  quantity: number
}

const BatteryView = ({ userId, token, battery, quantity }: Props) => {
  const { today } = getDateInfo()

  const formatTotal = useMemo(() => {
    return formatMoney(battery.current_total)
  }, [battery.current_total])

  const showQuantity = battery?.is_automatic !== null && !battery?.is_automatic

  return (
    <BContainer>
      <Title />
      <CenterBox>
        <AvailableView total={formatTotal} lastUpdate={today} dateLimit={battery?.date_limit} />
      </CenterBox>
      {showQuantity && (
        <CenterBox>
          <NextAssignment value={formatMoney(quantity.toString())} />
        </CenterBox>
      )}
      <ModeBar userId={userId} token={token} />
      <Historical userId={userId} token={token} />
    </BContainer>
  )
}

type AvailableProps = {
  total: string
  lastUpdate: string
  dateLimit: string | null
}

const AvailableView = ({ total, lastUpdate, dateLimit }: AvailableProps) => {
  const { t } = useTranslation()

  return (
    <AvailableBox sx={borderStyle}>
      <AvailableCenterBox>
        <CBox>
          <Typography variant='h5' sx={{ ml: 2 }}>
            {t(`youHave`)}
          </Typography>
          <Typography variant='body1' sx={marginStyle}>
            {t(`totalBalance`)}
          </Typography>
        </CBox>
      </AvailableCenterBox>

      <CenterBox>
        <Box>
          <CenterBox>
            <OrangeTypography variant='h1'>{total}</OrangeTypography>
          </CenterBox>
          <CenterTypography variant='body1'>{`${t('lastUpdate')} ${lastUpdate}`}</CenterTypography>
          {/*
           {dateLimit && (
            <LimitDateText variant='body1'>
              <WarningIcon />
              {`${t('limitDate')} ${formatDate(new Date(dateLimit))}`}
            </LimitDateText>
          )}
          */}
        </Box>
      </CenterBox>
    </AvailableBox>
  )
}

type NextAssignmentProps = {
  value: string
}

const NextAssignment = ({ value }: NextAssignmentProps) => {
  const { t } = useTranslation()

  return (
    <AvailableBoxGrey sx={borderStyle}>
      <AvailableCenterBox>
        <CBox>
          <Typography variant='h5' sx={{ ml: 2 }}>
            {t(`assignmentBlock.title`)}
          </Typography>
          <Typography variant='body1' sx={marginStyle}>
            {t(`assignmentBlock.subtitle`)}
          </Typography>
        </CBox>
      </AvailableCenterBox>

      <CenterBox>
        <Box>
          <CenterBox>
            <BoldTypography variant='h2'>{value}</BoldTypography>
          </CenterBox>
        </Box>
      </CenterBox>
    </AvailableBoxGrey>
  )
}

export default BatteryView
