import * as S from './styled'

const ErrorPage = () => {
  return (
    <S.ErrorPageContainer>
      <S.ErrorContainer className='layout-wrapper'>
        <S.ErrorPageComponent />
      </S.ErrorContainer>
    </S.ErrorPageContainer>
  )
}

export default ErrorPage
