import { ReactNode, useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Tooltip from 'components/atoms/tooltip'
import * as S from './styled'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'

import { SupplyPointsModel, SupplyPointsToSave } from 'model/interfaces/supply-points'
import CheckCell from 'components/molecules/check-cell'
import Button from 'components/atoms/button'
import BannerInfo from 'components/atoms/banner-info'

import { theme } from 'styles/theme'

import { ButtonBox, buttonStyle } from './styled'

type Props = {
  supplyPoints: SupplyPointsModel[]
  total?: number
  onSave: (supplyPointsList: SupplyPointsToSave[]) => void
  onCancel: () => void
  isFetching: boolean
  isSaving: boolean
  errors: string[]
}

type ItemFieldProps = {
  children: ReactNode
  label: string
}

type Item = {
  supply: string
  enabled: boolean
  quantity: string
}

const ItemField = ({ children, label }: ItemFieldProps) => (
  <S.ItemField hasMarginLeft>
    <S.Label>{label}</S.Label>
    {children}
  </S.ItemField>
)

const SupplyAutomatic = ({ supplyPoints, onSave, onCancel, isFetching, isSaving }: Props) => {
  const { t } = useTranslation()

  const [enableSupplyPoints, setEnableSupplyPoints] = useState<any>([])

  useEffect(() => {
    setEnableSupplyPoints(supplyPoints)
  }, [supplyPoints])

  const onChangeCheck = useCallback(
    (event: any, params: any, value: boolean) => {
      const newItemIndex = enableSupplyPoints.findIndex((item: any) => item.supply === params.supply)

      const newEnableSupplyPoints = [...enableSupplyPoints]
      const newItem = { ...newEnableSupplyPoints[newItemIndex] }
      newItem.enabled = value
      newEnableSupplyPoints[newItemIndex] = newItem

      setEnableSupplyPoints(newEnableSupplyPoints)
    },
    [enableSupplyPoints]
  )

  const onSaveSupplyPoints = useCallback(() => {
    const response = enableSupplyPoints.map((element: Item) => {
      return { supply: element.supply, quantity: '0.00', enabled: element.enabled }
    })
    onSave(response)
  }, [onSave, enableSupplyPoints])

  return (
    <S.BContainer>
      {supplyPoints?.map(item => {
        return (
          <S.Item key={item.supply}>
            <S.FRow>
              <CheckCell
                params={{ supply: item.supply, value: item.enabled }}
                boxSx={{ justifyContent: 'center' }}
                sx={{ textAlign: 'left' }}
                onChangeCheck={onChangeCheck}
              />
              <Tooltip title={item.address}>
                <S.AddressText>{item.address}</S.AddressText>
              </Tooltip>
            </S.FRow>
            <S.StateRow>
              <ItemField label={t`supplyActive`}>
                <S.Text>{item.supply_active ? t`active` : t`inactive`}</S.Text>
              </ItemField>
              {item.is_self_consumption ? (
                <S.AutoChip isAuto>{t`auto`}</S.AutoChip>
              ) : (
                <S.AutoChip>{t`notAuto`}</S.AutoChip>
              )}
            </S.StateRow>
          </S.Item>
        )
      })}
      {!enableSupplyPoints.find((item: any) => item.enabled) && (
        <BannerInfo
          align='center'
          iconLeft={<WarningAmberRoundedIcon style={{ color: theme.colors.blue }} />}
          bgColor={theme.colors.warning}
        >
          {t('warningAutomaticForm')}
        </BannerInfo>
      )}

      <ButtonSection
        onSave={onSaveSupplyPoints}
        onCancel={onCancel}
        isSaving={isSaving}
        hasErrors={!enableSupplyPoints.find((item: any) => item.enabled)}
      />
    </S.BContainer>
  )
}

type ButtonProps = {
  onSave: () => void
  onCancel: () => void
  isSaving: boolean
  hasErrors: boolean
}

const ButtonSection = ({ onSave, onCancel, isSaving = false, hasErrors }: ButtonProps) => {
  const { t } = useTranslation()
  return (
    <ButtonBox>
      <Button color='blue' variant='outlined' onClick={onCancel} sx={buttonStyle}>
        {t`cancel`}
      </Button>
      <Button color='orange' onClick={onSave} sx={buttonStyle} isLoading={isSaving} disabled={hasErrors}>
        {t`end`}
      </Button>
    </ButtonBox>
  )
}

export default SupplyAutomatic
