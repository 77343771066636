import styled from 'styled-components'
import Container from '@mui/material/Container'
import BannerInfo from 'components/atoms/banner-info'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { theme } from 'styles/theme'

export const BContainer = styled(Container)({
  background: theme.colors.white,
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '48px',
  padding: '0px',
})

export const CenterBox = styled(Box)({
  marginTop: '16px',
  marginBottom: '16px',
})

export const CenterTypography = styled(Typography)({
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
})

export const BoldTypography = styled(Typography)({
  fontWeight: 600,
})

export const TitleContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`

export const boxSt = { display: { xs: 'block', sm: 'block', md: 'none' } }

export const Banner = styled(BannerInfo)`
  margin-bottom: 16px;
`
