import { useTranslation } from 'react-i18next'
import RadioGroupInput from 'components/atoms/radio-group-input'
import * as Types from './types'
import * as S from './styled'

const View = ({ onChange, value, onSubmit, onClose, isLoading, disabled, ...props }: Types.PropsView) => {
  const { t } = useTranslation()

  const RenderLabel = ({ title, subtitle }: Types.Label) => (
    <S.Label>
      <S.LabelTitle>{title}</S.LabelTitle>
      <S.LabelSubtitle>{subtitle}</S.LabelSubtitle>
    </S.Label>
  )

  return (
    <S.Container onSubmit={onSubmit} {...props}>
      <RadioGroupInput
        value={value.is_automatic}
        name='is_automatic'
        onChange={onChange}
        disabled={isLoading}
        options={[
          {
            label: <RenderLabel title={t('mode.titleAuto')} subtitle={t('mode.subtitleAuto')} />,
            value: true,
          },
          {
            label: <RenderLabel title={t('mode.titleManual')} subtitle={t('mode.subtitleManual')} />,
            value: false,
          },
        ]}
      />
      <S.Footer>
        {onClose && (
          <S.FormButton
            type='button'
            onClick={onClose}
            disabled={isLoading || disabled}
            color='blue'
            variant='outlined'
          >
            {t('mode.cancel')}
          </S.FormButton>
        )}
        <S.FormButton type='submit' disabled={isLoading || disabled} isLoading={isLoading}>
          {t('mode.submit')}
        </S.FormButton>
      </S.Footer>
    </S.Container>
  )
}

export default View
