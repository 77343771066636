import styled from 'styled-components'
import { Typography } from '@mui/material'

import { theme } from 'styles/theme'

export const AutoTypography = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
  }
`

export const Container = styled.div<{
  bgColor?: string
  align?: 'center' | 'inherit' | 'right' | 'left' | 'justify' | undefined
}>`
  && {
    display: flex;
    gap: 16px;
    padding: 16px;
    border-radius: 16px;
    background-color: ${({ bgColor }) => bgColor || theme.colors.greyLight};
    justify-content: ${({ align }) => align || 'left'};
  }
`

export const Icon = styled.div`
  && {
  }
`
