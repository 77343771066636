export default {
  translation: {
    ok: 'OK',
    yourVirtualBattery: 'Batería virtual',
    psSelection: 'Selección de puntos de suministro',
    psDescription: 'Selecciona los puntos de suministros que deseas incluir para la descarga. ',
    cancel: 'Cancelar',
    end: 'Finalizar',

    mode: {
      submit: 'Continuar',
      cancel: 'Cancelar',
      subtitle:
        'Te damos la bienvenida a tu Batería Virtual. Antes de acceder a tu saldo y movimientos, selecciona la modalidad de descarga. Podrás modificar tu modalidad en cualquier momento.',
      titleManual: 'Modo manual',
      subtitleManual:
        'Con esta modalidad, podrás acceder a tu batería tantas veces como consideres al mes, revisar el saldo disponible y aplicar los € que consideres en cada punto de suministro. Si al final del mes la factura es inferior al descuento aplicado, se te devolverá el saldo no utilizado a la batería.',
      titleAuto: 'Modo automático',
      subtitleAuto:
        'En esta modalidad iremos descontando tu saldo en las próximas facturas tratando de aplicar el máximo descuento y dejar tu factura a 0. El saldo se aplicará en orden de “llegada” de las facturas.',
      confirm: {
        title: 'Cambio de modalidad',
        subtitle: 'Vas a cambiar al modo de descargas ',
        subtitle2: 'manual',
        textTitle: '¿Quieres continuar con el cambio?',
        text1: 'Con la ',
        text2: 'modalidad manual, ',
        text3: 'podrás acceder a tu batería tantas veces como consideres al mes, ',
        text4: 'revisar el saldo disponible y aplicar los €  ',
        text45: 'que consideres en cada punto de suministro.',
        text5: 'Si al final del mes la factura es inferior al descuento aplicado,  ',
        text56: 'se te devolverá el saldo no utilizado a la batería.',
        text6: 'Recuerda que',
        text7: 'tendrás que acceder periódicamente ',
        text8: 'para asignar el saldo a las diferentes facturas.',
      },
      confirmAuto: {
        title: 'Cambio de modalidad',
        subtitle: 'Vas a cambiar al modo de descargas ',
        subtitle2: 'automático',
        textTitle: '¿Quieres continuar con el cambio?',
        text1: 'En la ',
        text2: 'modalidad automática, ',
        text3:
          'las descargas se generan descontando tu saldo en las próximas facturas tratando de aplicar el máximo descuento y ',
        text4: 'reduciendo el importe de la factura.',
        text5: 'El saldo se aplicará en orden de “llegada” de las facturas.',
      },
    },

    modeBar: {
      title: 'Modalidad de descargas',
      change: 'Cambiar',
    },

    editModeBar: {
      titleM: 'Gestión de saldos',
      title: 'Gestión de puntos de suministros',
      edit: 'Editar',
      editM: 'Ver y asignar',
    },

    confirmAuto: {
      title: 'Cambio de modalidad',
      subtitle: 'Vas a cambiar al modo de descargas ',
      subtitle2: 'automático',
      textTitle: '¿Quieres continuar con el cambio?',
      text1: 'En la ',
      text2: 'modalidad automática, ',
      text3:
        'las descargas se generan descontando tu saldo en las próximas facturas tratando de aplicar el máximo descuento y ',
      text4: 'reduciendo el importe de la factura.',
      text5: 'El saldo se aplicará en orden de “llegada” de las facturas.',
    },

    assign: 'Asignación de descargas',
    how: 'A continuación, puedes indicar el saldo que quieres descargar en tus puntos de suministro. ',
    available: 'Saldo disponible',
    save: 'Guardar',
    dischargeBtn: 'Descartar',
    quantitityDischarge: 'Descarga en €',

    assignmentBlock: {
      title: 'Tienes asignado:',
      subtitle: 'Saldo asignado que se descargará en tus siguientes facturas',
    },

    youHave: 'Tienes disponible:',
    total: 'Total',
    totalBalance: 'Saldo acumulado de tus excedentes no consumidos.',
    lastUpdate: 'Última actualización',
    limitDate: 'Tu saldo caduca el ',
    historical: 'Últimos movimientos',
    historicalLimitDate:
      'Has dado de baja el servicio el día {{date}}. Tienes 45 días a partir de esa fecha para utilizar tu saldo disponible. En el caso de que tengas la configuración automática, se descontará de tu factura final.',
    accounting_date: 'Fecha de emisión',
    address: 'Dirección',
    factnum: 'Nro. de factura',
    is_self_consumption: 'Descripción',
    charge: 'Carga',
    discharge: 'Descarga',

    quantity: 'Importe',
    supply: 'Nro. de Suministro:',
    auto: 'Autoconsumo',
    notAuto: 'Sin autoconsumo',

    infoField: {
      auto: 'En esta columna encontrarás información sobre el importe de movimiento.',
      notAuto: 'Caducado: saldo que ha caducado tras 45 días de baja de la batería o tras 5 años desde su generación.',
      charge: 'Carga: saldo generado en la batería.',
      discharge: 'Descarga: Saldo utilizado del acumulado en la batería virtual, en la dirección elegida.',
      s_self_consumption:
        'Autoconsumo (para aquellas viviendas que pueden acumular energía en la batería y consumirlo) y Sin autoconsumo (para aquellas viviendas que pueden canjear el saldo de la batería)',
      rect: 'Factura rectificativa: rectificación de la carga debida a la rectificación de la factura original',
      quantity: 'Carga o saldo generado/utilizado en la batería',
      concept:
        'En esta columna encontrarás información sobre el importe de movimiento.\nCarga: saldo generado en la batería.\nDescarga: saldo utilizado del acumulado en la batería virtual, en la dirección elegida.\nCaducado: saldo que ha caducado tras 45 días de baja de la batería o tras 5 años desde su generación.\nFactura rectificativa: rectificación de la carga debida a la rectificación de la factura original',
    },

    concept: 'Movimiento',
    notFactNum: 'Ciclo de facturación en curso',

    noResultsReason: 'No hay movimientos para mostrar',
    noSupplyResultsReason: 'No hay puntos de suministro para mostrar',

    manual: 'Manual',
    automatic: 'Automático',

    download: 'Descarga en ',
    addressTable: 'Dirección',
    supplyTable: 'No. de suministro',
    supplyActive: 'Estado',
    active: 'Activo',
    inactive: 'Inactivo',

    manualAssignError: 'El saldo asignado es superior al saldo disponible. Por favor, revisa las asignaciones.',
    warningAutomaticForm: 'Selecciona al menos un punto de suministro para poder continuar.',
    genericError: 'Se ha producido un error. Por favor, inténtelo de nuevo.',
    errorView: {
      title: '¡Ups! Algo ha fallado',
      subtitle: 'El resumen de tu factura no está disponible en estos instantes.',
      content: 'Inténtalo más tarde',
      loginTitle: 'Identificación errónea. Por favor, inténtalo de nuevo.',
      againLogin: 'Vuelve a logarte en Area Clientes para acceder a tu bateria virtual',
      contactService: 'o contacta con ',
      customerArea: 'Área Clientes',
      customerService: 'Atención Cliente',
    },
  },
}
