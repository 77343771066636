import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import Tooltip from 'components/atoms/tooltip'
import { formatDate, parseUtcISO } from 'utils/dates'
import * as S from './styled'
import { formatMoney } from 'utils/numbers'
import HistoricalItemModel from 'model/interfaces/historical'

type Props = {
  value: HistoricalItemModel[]
}

type ItemFieldProps = {
  children: ReactNode
  label: string
}

const ItemField = ({ children, label }: ItemFieldProps) => (
  <S.ItemField>
    <S.Label>{label}</S.Label>
    {children}
  </S.ItemField>
)

const View = ({ value }: Props) => {
  const { t } = useTranslation()

  return (
    <S.BContainer>
      {value?.map(item => {
        let dateString = '-'
        if (item.accounting_date) {
          const formDate = parseUtcISO(item.accounting_date.toString())
          dateString = `${formatDate(formDate)}`
        }
        return (
          <S.Item key={item.id}>
            <S.AutoChip styleVariant='square'>{dateString}</S.AutoChip>
            {item.is_self_consumption ? (
              <S.AutoChip isAuto>{t`auto`}</S.AutoChip>
            ) : (
              <S.AutoChip>{t`notAuto`}</S.AutoChip>
            )}
            <ItemField label={t`address`}>
              <Tooltip title={item.address}>
                <S.Text>{item.address}</S.Text>
              </Tooltip>
            </ItemField>
            <ItemField label={t`factnum`}>
              <S.Text>{item.factnum || t`notFactNum`}</S.Text>
            </ItemField>
            <ItemField label={t`concept`}>
              <S.Text small>{item.concept}</S.Text>
            </ItemField>
            <ItemField label={t`quantity`}>
              <S.Text highlighted>{formatMoney(item.quantity, true)}</S.Text>
            </ItemField>
          </S.Item>
        )
      })}
    </S.BContainer>
  )
}

export default View
