import styled, { css } from 'styled-components'
import Container from 'components/atoms/container'
import Chip from 'components/atoms/chip'
import Typography from '@mui/material/Typography'

import { theme } from 'styles/theme'

export const BContainer = styled(Container)({
  background: theme.colors.white,
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '10px',
})

export const Item = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  column-gap: 4%;
  row-gap: 24px;
  border-bottom: solid 1px ${theme.colors.blueLight};
  padding-top: 16px;
  padding-bottom: 16px;
`

interface AutoChipProps {
  isAuto?: boolean | undefined
  styleVariant?: string | undefined
}

export const AutoChip = styled(Chip)<AutoChipProps>`
  && {
    background-color: ${({ isAuto }) => (isAuto ? theme.colors.green : theme.colors.blue)};
    &&& {
      padding: 0px;
    }
  }
`

export const ItemField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const Label = styled(Typography)``

export const textHighLighted = css`
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
`

export const smHighLighted = css`
  font-weight: 600;

  line-height: 24px;
`

interface TextProps {
  highlighted?: boolean | undefined
  small?: boolean | undefined
}

export const Text = styled(Typography)<TextProps>`
  && {
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    ${({ highlighted }) => (highlighted ? textHighLighted : '')};
    ${({ small }) => (small ? smHighLighted : '')}
  }
`
