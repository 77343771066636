import styled from 'styled-components'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { theme } from 'styles/theme'

export const TitleBox = styled(Box)({
  background: theme.colors.white,
  width: '400px',
  alignItems: 'center',
})

export const CenterBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  @media (max-width: ${({ theme }) => theme.breakpoints?.values['md']}px) {
    margin-bottom: 32px;
  }
`

export const FBox = styled(Box)({
  display: 'flex',
})

export const TitleTypography = styled(Typography)`
  && {
    font-size: 48px;
    font-weight: 100;
    line-height: 53px;
    letter-spacing: 0px;
    text-align: left;
    @media (max-width: ${({ theme }) => theme.breakpoints?.values['md']}px) {
      font-size: 36px;
    }
  }
`

export const BatteryImage = styled.img`
  width: 168px;
  max-width: 28%;
  @media (max-width: ${({ theme }) => theme.breakpoints?.values['md']}px) {
    display: none;
  }
`
