import React from 'react'
import * as S from './styled'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import { theme } from 'styles/theme'

const BannerInfo = ({
  className,
  children,
  iconLeft,
  bgColor,
  align,
  ...props
}: {
  className?: string
  children?: React.ReactNode
  iconLeft?: any
  bgColor?: string
  align?: 'center' | 'inherit' | 'right' | 'left' | 'justify' | undefined
}) => (
  <S.Container bgColor={bgColor} align={align} className={className}>
    {iconLeft && <S.Icon>{iconLeft}</S.Icon>}
    <S.AutoTypography {...props}>{children}</S.AutoTypography>
  </S.Container>
)

BannerInfo.defaultProps = {
  iconLeft: <ErrorOutlineRoundedIcon style={{ color: theme.colors.orange }} />,
}

export default BannerInfo
