import { DataGrid } from '@mui/x-data-grid'
import { styled } from '@mui/material/styles'
import { Box, Container, Typography } from '@mui/material'

import TooltipComp from 'components/atoms/tooltip'
import ContainerComp from 'components/atoms/container'
import { Info } from 'assets/icons'

import { theme } from 'styles/theme'

export const StyledDataGrid = styled(DataGrid)(({ theme: muiTheme }) => ({
  border: 0,
  color: muiTheme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  '& .MuiDataGrid-footerContainer': {
    display: 'none',
  },
  '& .MuiDataGrid-virtualScroller': {
    overflow: 'hidden',
    minHeight: '70px',
  },
  '& .MuiDataGrid-main': {
    background: theme.colors.white,
    marginBottom: '20px',
    borderBottom: `1px solid ${muiTheme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
    borderTop: `1px solid ${muiTheme.palette.mode === 'light' ? '#99B5C6' : '#303030'}`,

    '.defaultHeader, .defaultTextColumn, .defaultBoldHeader, .defaultBoldTextColumn': {
      color: theme.colors.blue,

      textAlign: 'center',

      a: {
        color: theme.colors.blue,
        textDecoration: 'none',
      },

      '&.not-overflow': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },

    '.defaultBoldHeader, .defaultBoldTextColumn': {
      fontWeight: 600,
    },
    '.defaultDateText': {
      color: theme.colors.blue,

      fontWeight: 600,
      textAlign: 'center',
      a: {
        color: theme.colors.blue,
        textDecoration: 'none',
      },

      '&.not-overflow': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
  '& .MuiDataGrid-columnsContainer': {
    backgroundColor: muiTheme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.colors.blueLight,
    borderBottom: `0px solid ${muiTheme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
    borderTop: `0px solid ${muiTheme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
  },
  '& .MuiDataGrid-columnHeader': {},
  '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${muiTheme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
    borderTop: `0px solid ${muiTheme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
  },
  '& .MuiDataGrid-cell': {
    color: muiTheme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
  },
  '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
}))

export const AutomaticStyledDataGrid = styled(DataGrid)(({ theme: muiTheme }) => ({
  border: 0,
  color: muiTheme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  '& .MuiDataGrid-footerContainer': {
    display: 'none',
  },
  '& .MuiDataGrid-virtualScroller': {
    overflow: 'hidden',
    minHeight: '70px',
  },
  '& .MuiDataGrid-main': {
    minHeight: '200px',
    background: theme.colors.white,
    marginBottom: '20px',

    '.defaultHeader, .defaultTextColumn, .defaultBoldHeader, .defaultBoldTextColumn': {
      color: theme.colors.blue,

      textAlign: 'center',

      a: {
        color: theme.colors.blue,
        textDecoration: 'none',
      },

      '&.not-overflow': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },

  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.colors.blueLight,
    borderBottom: `0px solid ${muiTheme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
    borderTop: `0px solid ${muiTheme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
  },
}))

export const CContainer = styled(ContainerComp)`
  padding: 0px;
`
export const SContainer = styled(Box)({
  padding: '16px',
  alignItems: 'center',
})

export const AutoTypography = styled(Typography)({
  marginTop: '10px',
  textAlign: 'center',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  color: theme.colors.white,
  fontWeight: 600,
  paddingLeft: '12px',
  paddingRight: '12px',
  borderRadius: '25px',
  width: '120px',
  height: '30px',
  background: theme.colors.green,
  fontSize: '12px',
  marginBottom: '10px',
})

export const CustomBox = styled(Box)({
  flex: 1,
  display: 'flex',

  alignItems: 'center',
  justifyContent: 'center',
})

export const NoPaddingContainer = styled(Container)`
  padding: 0px;
`
export const NotAutoTypography = styled(AutoTypography)({
  background: theme.colors.blue,
})

export const InfoIcon = styled(Info)`
  && {
    width: 17px;
    margin-left: 5px;
  }
`
export const CenterBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const CenterTypography = styled(Typography)({
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
})

export const FBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'end',
  width: '100%',
})

export const ButtonBox = styled(Box)({
  marginTop: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
})

export const BContainer = styled(CContainer)({
  background: theme.colors.white,
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
})

export const NoBorderContainer = styled(NoPaddingContainer)`
  && {
    padding: 16px;
    border-radius: 10px;
  }
`

export const AContainer = styled(NoBorderContainer)({
  background: 'transparent',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
})

export const TBox = styled(Box)({
  flex: 1,
  display: 'grid',
  justifyContent: 'center',
})

export const Tooltip = styled(TooltipComp)``

export const BoldTypography = styled(Typography)`
  && {
    @media (max-width: ${({ theme }) => theme.breakpoints?.values['md']}px) {
      font-size: 24px;
    }
  }
`

export const TitleTypography = styled(Typography)`
  && {
    font-weight: 250;
    @media (max-width: ${({ theme }) => theme.breakpoints?.values['md']}px) {
      font-size: 48px;
    }
  }
`

export const SmallTypography = styled(Typography)`
  && {
    font-weight: 500;
    margin-top: 36px;
  }
`

export const OrangeTypography = styled(Typography)`
  && {
    font-weight: 600;
    color: ${theme.colors.orange};
  }
`

export const inputStyle = {
  marginTop: '12px',
  color: theme.colors.blue,
  input: { color: theme.colors.blue },
}

export const buttonStyle = {
  marginRight: '16px',
  marginLeft: '16px',
  width: '200px',
}

export const skelleton = { paddingTop: '22px' }

export const skelletonItem = {
  display: 'flex',
  paddingLeft: 20,
}

export const addSkel = { marginLeft: '200px' }

export const btnSkel = { marginLeft: '50px' }

export const txtSx = {
  marginTop: '12px',
  color: theme.colors.blue,
  input: { color: theme.colors.blue },
}

export const autoBox = { display: { xs: 'none', sm: 'none', md: 'block' } }

export const btnBlue = { marginRight: '16px', marginLeft: '16px', width: '200px' }

export const btnOrange = { marginLeft: '16px', marginRight: '16px', width: '200px' }

export const boxSt = { display: { xs: 'block', sm: 'block', md: 'none' } }

export const boxDiv = { display: { xs: 'none', sm: 'none', md: 'block' } }
