import React from 'react'
import spinner from 'assets/images/naturgy-logo-spinner.gif'

import * as S from './styled'

const Spinner = ({ ...props }) => (
  <S.Spinner {...props}>
    <S.SpinnerImage src={spinner} alt='loading' />
  </S.Spinner>
)

export default Spinner
