import { useTranslation } from 'react-i18next'
import * as Types from './types'
import * as S from './styled'

const View = ({ onSuccess, onCancel, isAutomatic, isLoading, ...props }: Types.Props) => {
  const { t } = useTranslation()
  if (isAutomatic) {
    return (
      <S.Container {...props}>
        <S.Header>
          <S.Title>{t('mode.confirmAuto.title')}</S.Title>
          <S.Text>
            {t('mode.confirmAuto.subtitle')} <S.Bold>{t('mode.confirmAuto.subtitle2')}</S.Bold>
          </S.Text>
        </S.Header>
        <S.Content>
          <S.TextTitle>{t('mode.confirmAuto.textTitle')}</S.TextTitle>
          <S.Text>
            {t('mode.confirmAuto.text1')}
            <S.Bold>{t('mode.confirmAuto.text2')}</S.Bold>
            {t('mode.confirmAuto.text3')}
            <S.Bold>{t('mode.confirmAuto.text4')}</S.Bold>
          </S.Text>
          <S.Text>{t('mode.confirmAuto.text5')}</S.Text>
        </S.Content>
        <S.Footer>
          <S.Submit type='button' onClick={onCancel} color='blue' variant='outlined'>
            {t('mode.cancel')}
          </S.Submit>
          <S.Submit type='button' onClick={onSuccess} isLoading={isLoading}>
            {t('mode.submit')}
          </S.Submit>
        </S.Footer>
      </S.Container>
    )
  }
  return (
    <S.Container {...props}>
      <S.Header>
        <S.Title>{t('mode.confirm.title')}</S.Title>
        <S.Text>
          {t('mode.confirm.subtitle')} <S.Bold>{t('mode.confirm.subtitle2')}</S.Bold>
        </S.Text>
      </S.Header>
      <S.Content>
        <S.TextTitle>{t('mode.confirm.textTitle')}</S.TextTitle>
        <S.Text>
          {t('mode.confirm.text1')}
          <S.Bold>{t('mode.confirm.text2')}</S.Bold>
          {t('mode.confirm.text3')}
          <S.Bold>{t('mode.confirm.text4')}</S.Bold>
          <S.Text>{t('mode.confirm.text45')}</S.Text>
        </S.Text>
        <S.Text>
          {t('mode.confirm.text5')}
          <S.Bold>{t('mode.confirm.text56')}</S.Bold>
        </S.Text>
        <S.Text>
          {t('mode.confirm.text6')} <S.Bold>{t('mode.confirm.text7')}</S.Bold> {t('mode.confirm.text8')}
        </S.Text>
      </S.Content>
      <S.Footer>
        <S.Submit type='button' onClick={onCancel} color='blue' variant='outlined'>
          {t('mode.cancel')}
        </S.Submit>
        <S.Submit type='button' onClick={onSuccess}>
          {t('mode.submit')}
        </S.Submit>
      </S.Footer>
    </S.Container>
  )
}

export default View
