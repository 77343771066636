import { useState } from 'react'
import { ModeForm as ModeFormType } from 'model/interfaces/battery'
import View from './view'
import * as Types from './types'

const ModeForm = ({ onSubmit, onClose, defaultValue, ...props }: Types.Props) => {
  const [form, setForm] = useState<ModeFormType>(defaultValue || { is_automatic: true })

  const handleChange = ({ value, name }: { value: any; name: string }) => {
    const newForm: any = { ...form }
    if (name === 'is_automatic') {
      newForm[name] = value === 'true'
    } else {
      newForm[name] = value
    }
    setForm(newForm)
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    onSubmit(form)
  }

  const isDisabled = form?.is_automatic === null || form?.is_automatic === undefined

  return (
    <View
      value={form}
      onSubmit={handleSubmit}
      onChange={handleChange}
      disabled={isDisabled}
      onClose={onClose}
      {...props}
    />
  )
}

export default ModeForm
