import * as Types from './types'

import * as S from './styled'

const ButtonBlock = ({ onClick, buttonEndIcon, title, subtitle, buttonText, isLoading }: Types.Props) => (
  <S.Container>
    <S.Wrapper>
      {title && <S.Title>{title}:</S.Title>}
      <S.Subtitle>{subtitle}</S.Subtitle>
    </S.Wrapper>
    <S.ChangeButton isLoading={isLoading} variant='outlined' color={'blue'} onClick={onClick} endIcon={buttonEndIcon}>
      {buttonText}
    </S.ChangeButton>
  </S.Container>
)

export default ButtonBlock
