import { useCallback, useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'

import { fetchHistorical } from 'api'

const useHistorical = (userId?: string, token?: string) => {
  const [page, setPage] = useState(1)

  const {
    isError,
    data: historicalData,
    isFetching,
    ...rest
  } = useQuery({
    queryKey: ['historical', { userId }, page],

    queryFn: () => {
      console.log('llamada')
      return fetchHistorical(page)
    },
    keepPreviousData: true,
    enabled: !!userId,
  })

  const historicalList = useMemo(() => {
    return historicalData?.data?.data || []
  }, [historicalData?.data])

  const onNextPage = useCallback(() => {
    if (historicalData?.data?.currentPage < historicalData?.data?.totalPages) {
      setPage(historicalData?.data?.currentPage + 1)
    }
  }, [historicalData?.data?.currentPage, historicalData?.data?.totalPages])

  const onPrevPage = useCallback(() => {
    if (page > 1) {
      setPage(historicalData?.data?.currentPage - 1)
    }
  }, [historicalData?.data?.currentPage, page])

  const onPage = useCallback((pageToFetch: number) => {
    setPage(pageToFetch)
  }, [])

  const totalPages = useMemo(() => {
    return historicalData?.data?.totalPages || 0
  }, [historicalData?.data?.totalPages])

  const hasNextPage = useMemo(() => {
    return historicalData?.data?.currentPage < historicalData?.data?.totalPages
  }, [historicalData?.data?.currentPage, historicalData?.data?.totalPages])

  return {
    historicalList,
    pageNumber: page,
    hasNextPage,
    onNextPage,
    onPrevPage,
    onPage,
    isFetching,
    totalPages,
    isError,
    key: ['historical', { userId }, page],
    ...rest,
  }
}

export default useHistorical
