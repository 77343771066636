import styled from 'styled-components/macro'
import ErrorPage from 'components/organisms/errorPage'

export const ErrorPageContainer = styled.div`
  .layout-wrapper {
    width: 100%;
    margin: auto;
  }
`

export const ErrorContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const ErrorPageComponent = styled(ErrorPage)``
