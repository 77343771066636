import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { GridColumnHeaderParams, GridValidRowModel } from '@mui/x-data-grid'
import Typography from '@mui/material/Typography'

import HistoricalItemModel from 'model/interfaces/historical'
import { CustomBox, InfoIcon, Tooltip } from 'components/organisms/historical-table/styled'

type HistoricalRow = HistoricalItemModel & GridValidRowModel

type Props = {
  params: GridColumnHeaderParams<HistoricalRow>
  infoField?: string | null
}

const Header: FC<Props> = ({ params, infoField }) => {
  const { t } = useTranslation()

  return (
    <CustomBox>
      <Typography variant='body1' className='defaultBoldHeader'>
        {t(params.field)}
      </Typography>
      {infoField && (
        <Tooltip title={infoField}>
          <InfoIcon />
        </Tooltip>
      )}
    </CustomBox>
  )
}

export default Header
