export const getPrefix = (value: string | null = '0') => {
  if (typeof value === 'string' && value[0] === '-') {
    return ''
  }
  const fValue = parseFloat(value?.replace(',', '.') || '0')
  if (fValue == 0) {
    return ''
  }
  return '+'
}

export const formatMoney = (total = '0', hasPrefix = false, integerFormat = false) => {
  const totalVal = integerFormat ? parseInt(total) : parseFloat(total)

  const nVal = integerFormat
    ? totalVal.toLocaleString('es')
    : totalVal.toLocaleString('es', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
  return `${hasPrefix ? getPrefix(nVal) : ''}${nVal} €`
}
