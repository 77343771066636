import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { Box, ClickAwayListener } from '@mui/material'
import { Info } from 'assets/icons'

import Tooltip from 'components/atoms/tooltip'
import { BContainer, CenterBox, TitleContent, boxSt, Banner } from './styled'
import HistoricalTable from '../historical-table'
import BatteryModel from 'model/interfaces/battery'
import { formatDate } from 'utils/dates'

type Props = {
  userId: string
  token: string
  battery?: BatteryModel
  open: boolean
  onOpen: () => void
  onClose: () => void
}

const HistoricalView = ({ userId, token, open, onOpen, onClose, battery }: Props) => {
  const { t } = useTranslation()
  return (
    <BContainer>
      <CenterBox>
        <Box>
          <TitleContent>
            <Typography variant='h5'>{t(`historical`)}</Typography>
            <Box component='div' sx={boxSt}>
              <Tooltip
                title={
                  <>
                    <Typography color='inherit'>{t('infoField.auto')}</Typography>
                    <Typography color='inherit'>{t('infoField.charge')}</Typography>
                    <Typography color='inherit'>{t('infoField.discharge')}</Typography>
                    <Typography color='inherit'>{t('infoField.notAuto')}</Typography>
                    <Typography color='inherit'>{t('infoField.rect')}</Typography>
                  </>
                }
              >
                <Info />
              </Tooltip>
            </Box>
          </TitleContent>
        </Box>
      </CenterBox>
      {battery?.date_disabled && (
        <Banner>{t('historicalLimitDate', { date: formatDate(new Date(battery?.date_disabled)) })}</Banner>
      )}
      <HistoricalTable userId={userId} token={token} />
    </BContainer>
  )
}
export default HistoricalView
