import BatteryView from './view'

import { postIframeHeightEvent } from 'utils/functions'
import useVirtualBattery from 'hooks/use-battery'
import { useEffect } from 'react'
import { ROUTES } from 'config/routes'
import { useNavigate } from 'react-router-dom'
import useSupplyPoints from 'hooks/use-supply-points'

type Props = {
  userId: string
  token: string
}

const Battery = ({ userId, token }: Props) => {
  const { battery, isFetched } = useVirtualBattery(userId, token)
  const { supplyPoints, isFetching } = useSupplyPoints(userId, token)

  const quantity = supplyPoints.reduce((r, v) => r + (parseFloat(v.quantity?.toString()) || 0), 0)

  const navigate = useNavigate()

  useEffect(() => {
    postIframeHeightEvent(false)
  })

  if (!battery && isFetched) {
    navigate(ROUTES.error)
    return null
  }

  if (!battery) return null

  return <BatteryView userId={userId} battery={battery} token={token} quantity={quantity} />
}

export default Battery
