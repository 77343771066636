import { useQuery } from '@tanstack/react-query'

import { fetchSupplyPoints } from 'api'
import { SupplyPointsResponse } from 'model/interfaces/supply-points'

type SupplyPointsQueryKey = readonly ['supplyPoints', { userId?: string; token?: string }]

const useSupplyPoints = (userId?: string, token?: string) => {
  const getSupplyTypes = (queryKey: SupplyPointsQueryKey) => {
    const { userId } = queryKey[1]

    return fetchSupplyPoints()
  }

  const { data, isFetching, ...rest } = useQuery<
    SupplyPointsResponse | null,
    Error,
    SupplyPointsResponse,
    SupplyPointsQueryKey
  >(
    ['supplyPoints', { userId }],
    ({ queryKey }) => {
      return getSupplyTypes(queryKey)
    },
    {
      keepPreviousData: true,
      enabled: !!userId,
    }
  )

  return {
    supplyPoints: data?.data.reserves || [],
    total: data?.data.total,
    isFetching,
    ...rest,
    key: ['supplyPoints', { userId }],
  }
}

export default useSupplyPoints
