import styled from 'styled-components'
import Typography from '@mui/material/Typography'
import Button from 'components/atoms/button'

import { theme } from 'styles/theme'

export const Container = styled.div`
  background-color: ${theme.colors.greyLight};
  border-radius: 20px;
  display: flex;
  justify-content: center;
  padding-right: 20px;
  width: 100%;
`

export const Wrapper = styled.div`
  background-color: ${theme.colors.greyLight};
  border-radius: 20px;
  display: grid;
  grid-template-areas:
    'title button'
    'subtitle button';
  row-gap: 0px;
  column-gap: 15px;
  padding: 16px;
  align-self: center;
  flex: 0.7;
`

export const Title = styled(Typography)`
  && {
    grid-area: title;
    font-size: 18px;
    font-weight: 600;
    max-width: 224px;
    line-height: 20px;
  }
`

export const Subtitle = styled(Typography)`
  && {
    grid-area: subtitle;
    max-width: 224px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0px;
    color: ${theme.colors.orange};
  }
`

export const ChangeButton = styled(Button)`
  && {
    flex: 0.3;
    grid-area: button;
    align-content: end;
    align-self: center;
    min-width: 111px;
  }
`
