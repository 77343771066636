import { ReactNode } from 'react'
import { ButtonProps } from '@mui/material/Button'
import { theme } from 'styles/theme'
import * as S from './styled'

interface CustomButtonProps extends Omit<ButtonProps, 'color'> {
  children: ReactNode
  color: keyof typeof theme.colors
  isLoading?: boolean
}

const Button = ({ children, color, isLoading, ...rest }: CustomButtonProps) => {
  return (
    <>
      {!isLoading && (
        <S.CustomButton {...rest} buttonColor={color}>
          {children}
        </S.CustomButton>
      )}
      {isLoading && (
        <S.CustomButton {...rest} buttonColor={color} startIcon={null} endIcon={null} disabled>
          <S.Loader style={{ width: '17px', height: '17px' }} />
        </S.CustomButton>
      )}
    </>
  )
}

Button.defaultProps = {
  className: '',
  children: '',
  color: S.defaultColor,
  variant: 'contained',
  type: 'button',
}

export default Button
