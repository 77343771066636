import { CircularProgress } from '@mui/material'
import Button, { ButtonProps } from '@mui/material/Button'
import { styled, darken, alpha } from '@mui/material/styles'
import { theme } from 'styles/theme'

export const defaultColor: keyof typeof theme.colors = 'orange'

export interface CustomButtonProps extends ButtonProps {
  buttonColor: keyof typeof theme.colors
}

export const CustomButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'buttonColor',
})<CustomButtonProps>(({ buttonColor, variant }) => ({
  color: variant === 'contained' ? theme.colors.white : theme.colors[buttonColor],
  backgroundColor: variant === 'contained' ? theme.colors[buttonColor] : 'transparent',
  borderColor: variant === 'outlined' ? theme.colors[buttonColor] : '',
  borderRadius: '25px',
  padding: '10px 16px',
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '17px',
  textTransform: 'none',
  '&.Mui-disabled': variant === 'contained' && {
    color: theme.colors.white,
    backgroundColor: alpha(theme.colors[buttonColor], 0.4),
  },
  '&:hover': {
    backgroundColor:
      variant === 'contained' ? darken(theme.colors[buttonColor], 0.15) : alpha(theme.colors[buttonColor], 0.1),
  },
}))

export const Loader = styled(CircularProgress)`
  color: white;
  width: 17px;
  height: 17px;
`
