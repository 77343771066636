import { useEffect } from 'react'
import useHistorical from 'hooks/use-infinite-history'
import { postIframeHeightEvent } from 'utils/functions'
import View from './view'

type Props = {
  userId: string
  token: string
}

const HistoricalTable = ({ userId, token }: Props) => {
  const { historicalList, onNextPage, onPrevPage, onPage, hasNextPage, pageNumber, totalPages } = useHistorical(
    userId,
    token
  )

  useEffect(() => {
    postIframeHeightEvent(false)
  })

  return (
    <View
      historical={historicalList}
      pagination={{ onNextPage, onPrevPage, onPage, hasNextPage, totalPages, page: pageNumber }}
    />
  )
}

export default HistoricalTable
