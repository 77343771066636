import { FC, useCallback, useMemo } from 'react'
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid'
import { Box, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import HistoricalItemModel from 'model/interfaces/historical'
import PaginationButtons from 'components/organisms/pagination-buttons'

import {
  ADDRESS_COLUMN_WIDTH,
  DATE_COLUMN_WIDTH,
  QUANTITY_COLUMN_WIDTH,
  SUPPLY_COLUMN_WIDTH,
  INVOICE_COLUMN_WIDTH,
} from './utils/constants'
import Cells from '../../molecules/cells/view'
import Headers from '../../molecules/headers/view'
import { StyledDataGrid, AutoTypography, StyledSmList, NotAutoTypography, cellSx, boxSt, boxDiv } from './styled'

interface TablePaginationProps {
  onNextPage: () => void
  onPrevPage: () => void
  onPage: (page: number) => void
  page: number
  hasNextPage?: boolean
  totalPages?: number
}

type Props = {
  historical: HistoricalItemModel[]
  pagination: TablePaginationProps
}

const HistoricalTableView = ({ historical, pagination }: Props) => {
  const { t } = useTranslation()

  const getPrefix = useCallback((value: string | null = '0') => {
    if (typeof value === 'string' && value[0] === '-') {
      return ''
    }
    if (value === '0') {
      return ''
    }
    return '+'
  }, [])

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'accounting_date',
        sortable: false,
        minWidth: DATE_COLUMN_WIDTH,
        renderCell: props => <Cells isDate params={props} sx={cellSx} />,
        renderHeader: props => <Headers params={props} />,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'address',
        sortable: false,
        minWidth: ADDRESS_COLUMN_WIDTH,
        renderCell: props => <Cells tooltip={props.value?.toString()} params={props} sx={cellSx} />,
        renderHeader: props => <Headers params={props} />,
        flex: 1,
        headerAlign: 'center',
        align: 'left',
      },

      {
        field: 'factnum',
        sortable: false,
        minWidth: INVOICE_COLUMN_WIDTH,
        renderCell: props => <Cells highlighted params={props} sx={cellSx} replaceNull={t`notFactNum`} />,
        renderHeader: props => <Headers params={props} />,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
      },

      {
        field: 'is_self_consumption',
        sortable: false,
        minWidth: SUPPLY_COLUMN_WIDTH,
        renderCell: props => (
          <Box>
            {props.value ? (
              <AutoTypography variant='body1'>{t`auto`}</AutoTypography>
            ) : (
              <NotAutoTypography variant='body1'>{t`notAuto`}</NotAutoTypography>
            )}
          </Box>
        ),
        renderHeader: props => <Headers params={props} infoField={t('infoField.s_self_consumption')} />,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'concept',
        sortable: false,
        minWidth: QUANTITY_COLUMN_WIDTH,
        renderCell: props => {
          return <Cells params={props} sx={cellSx} />
        },
        renderHeader: props => <Headers params={props} infoField={t('infoField.concept')} />,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'quantity',
        sortable: false,
        minWidth: QUANTITY_COLUMN_WIDTH,
        renderCell: props => {
          return <Cells highlighted params={props} prefix={getPrefix(props.value)} isImport sx={cellSx} />
        },
        renderHeader: (props: GridColumnHeaderParams<any, HistoricalItemModel, any>) => <Headers params={props} />,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
      },
    ],
    [getPrefix, t]
  )

  return (
    <>
      <Box component='div' sx={boxSt}>
        <StyledSmList value={historical} />
      </Box>
      <Box component='div' sx={boxDiv}>
        <StyledDataGrid
          getRowId={row => row.id}
          rows={historical}
          columns={columns}
          disableColumnMenu
          disableRowSelectionOnClick
          pagination
          paginationMode='server'
          rowCount={10000}
          slots={{
            noResultsOverlay: () => (
              <Stack height='100%' alignItems='center' justifyContent='center'>
                {t`noResultsReason`}
              </Stack>
            ),
            footer: () => TableFooter({ ...pagination }),
          }}
        />
      </Box>
    </>
  )
}

const CustomPagination: FC<TablePaginationProps> = ({ ...rest }: TablePaginationProps) => {
  return (
    <Stack direction='row' justifyContent='center' alignItems='center'>
      <PaginationButtons {...rest} />
    </Stack>
  )
}

const TableFooter = (props: TablePaginationProps) => {
  return (
    <Stack direction='column' spacing='40px' marginTop='40px'>
      {props?.totalPages && props?.totalPages > 1 ? <CustomPagination {...props} /> : null}
    </Stack>
  )
}

export default HistoricalTableView
