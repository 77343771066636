import { styled } from '@mui/material/styles'
import TooltipComp from 'components/atoms/tooltip'

import ContainerComp from 'components/atoms/container'

import { theme } from 'styles/theme'
import { Box, Container, Typography } from '@mui/material'

export const CContainer = styled(ContainerComp)`
  padding: 0px;
`

export const TitleContainer = styled(Container)`
  && {
    padding-left: 16px;
    padding-right: 16px;
  }
`

export const Tooltip = styled(TooltipComp)``

export const TitleBox = styled(Box)({
  background: theme.colors.white,
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '30px',
  marginTop: '20px',
})

export const BoldTypography = styled(Typography)`
  && {
    @media (max-width: ${({ theme }) => theme.breakpoints?.values['md']}px) {
      font-size: 24px;
    }
  }
`

export const TitleTypography = styled(Typography)`
  && {
    font-weight: 250;
    @media (max-width: ${({ theme }) => theme.breakpoints?.values['md']}px) {
      font-size: 30px;
    }
  }
`

export const SmallTypography = styled(Typography)`
  && {
    margin-top: 8px;
    font-size: 14px;
    width: 100%;
  }
`

export const OrangeTypography = styled(Typography)`
  && {
    font-weight: 600;
    color: ${theme.colors.orange};
    @media (max-width: ${({ theme }) => theme.breakpoints?.values['md']}px) {
      font-size: 32px;
    }
  }
`
