import { styled } from '@mui/material/styles'
import Container from '@mui/material/Container'

import { theme } from 'styles/theme'

export const ContainerComp = styled(Container)`
  && {
    box-shadow: 1px 12px 8px ${theme.colors.blueLight};
    padding: 16px;
    border-radius: 10px;
  }
`
