import styled from 'styled-components'
import { Typography } from '@mui/material'
import Button from 'components/atoms/button'

import { theme } from 'styles/theme'

export const Container = styled.div``

export const Title = styled(Typography)`
  && {
    font-size: 48px;
    font-weight: 250;
    font-weight: 100;
  }
`

export const Text = styled(Typography)`
  && {
    font-size: 14px;
    margin-bottom: 20px;
  }
`

export const TextTitle = styled(Typography)`
  && {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;
  }
`

export const Bold = styled.span`
  && {
    font-size: 14px;
    font-weight: 500;
  }
`

export const Content = styled.div`
  && {
    font-size: 18px;
  }
`

export const Footer = styled.div`
  && {
    display: flex;
    justify-content: center;
    margin-top: 66px;
    gap: 10px;
  }
`

export const Submit = styled(Button)`
  && {
    padding: 15px;
    font-size: 18px;
    max-width: 100%;
    width: 243px;
  }
`

export const Header = styled.div`
  margin-bottom: 40px;
`
