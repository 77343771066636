import { FunctionComponent, MouseEventHandler, SVGProps } from 'react'
import * as S from './styled'

/**
 	Use:
 	<Icon
 		Source={CloseIcon} -> icon source in src/assets/icons/index.ts
 		width={'22px'} -> width
 		height={'22px'} -> height
 		color={'#E57200'} -> color (${theme.colors.orange} by default)
 	/>
*/

interface IconProps {
  Source: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>
  width: string
  height: string
  top: string
  left: string
  color: string
  title: string
  tooltip: boolean
  tooltipTitle: string
  onClick: MouseEventHandler<HTMLDivElement>
}

const Icon = ({ Source, width, height, top, left, color, title, tooltip, tooltipTitle, onClick }: IconProps) => {
  return (
    <S.IconContainer
      className='icon-container'
      color={color}
      width={width}
      height={height}
      top={top}
      left={left}
      title={title}
      onClick={onClick}
    >
      <Source title={title} className='icon' width={width} height={height} />
      {tooltip && <S.Tooltip className='tooltip'>{tooltipTitle}</S.Tooltip>}
    </S.IconContainer>
  )
}

Icon.defaultProps = {
  Source: {},
  width: '16px',
  height: '16px',
  top: '',
  left: '',
  color: S.defaultColor,
  onClick: null,
  title: '',
  tooltip: false,
  tooltipTitle: '',
}

export default Icon
