import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styled'
import { theme } from 'styles/theme'
import { CUSTOMER_AREA, CUSTOMER_SERVICE_CT, CUSTOMER_SERVICE_ES, CUSTOMER_SERVICE_GL } from 'utils/constants'

const ErrorPage = ({ ...props }) => {
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language
  const [linkLng, setLinkLng] = useState(currentLanguage)

  useEffect(() => {
    if (currentLanguage === 'es') {
      setLinkLng(CUSTOMER_SERVICE_ES)
    }
    if (currentLanguage === 'ct') {
      setLinkLng(CUSTOMER_SERVICE_CT)
    }
    if (currentLanguage === 'gl') {
      setLinkLng(CUSTOMER_SERVICE_GL)
    }
  }, [currentLanguage])

  const getTexts = errorType => {
    if (errorType === 403) return { title: t('errorView.loginTitle') }
    return {
      title: t('errorView.title'),
      subtitle1: t('errorView.againLogin'),
      subtitle2: t('errorView.contactService'),
      linkText: t('errorView.customerService'),
      link1: CUSTOMER_AREA,
    }
  }

  const { title, subtitle1, subtitle2, linkText, link1 } = getTexts()

  return (
    <S.ErrorPageContainer {...props}>
      <S.ErrorPageWrapper>
        <S.TitleContainer>
          <S.Title fontColor={theme.colors.blue.regular} fontType='regular'>
            {title}
          </S.Title>
        </S.TitleContainer>
        <S.ContentContainer>{link1 && <span>{subtitle1}</span>}</S.ContentContainer>
        <S.ContentContainer>
          <>
            <span>{subtitle2}</span>
            <a href={linkLng} target='_blank' rel='noopener noreferrer'>
              {linkText}
            </a>
          </>
        </S.ContentContainer>
      </S.ErrorPageWrapper>
    </S.ErrorPageContainer>
  )
}

export default ErrorPage
