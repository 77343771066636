export default {
  translation: {
    ok: 'OK',
    yourVirtualBattery: 'Bateria virtual',
    psSelection: 'Selecció de punts de subministrament',
    psDescription: 'Selecciona els punts de subministrament que vols incloure per a la descàrrega. ',
    cancel: 'Cancel·la',
    end: 'Acaba',

    mode: {
      submit: 'Continua',
      cancel: 'Cancel·la',
      subtitle:
        'Et donem la benvinguda a la teva bateria virtual. Abans d’accedir al saldo i als moviments, selecciona la modalitat de descàrrega. Pots modificar la modalitat en qualsevol moment.',
      titleManual: 'Mode manual',
      subtitleManual:
        'Amb aquesta modalitat, podràs accedir a la bateria tantes vegades al mes com necessitis, revisar el saldo disponible i aplicar els euros que consideris en cada punt de subministrament. Si al final del mes la factura és inferior al descompte aplicat, se’t tornarà el saldo no utilitzat a la bateria.',
      titleAuto: 'Mode automàtic',
      subtitleAuto:
        "En aquesta modalitat t'anirem descomptant el saldo a les factures vinents provant d’aplicar el descompte màxim i deixar la factura a 0. El saldo s’aplicarà en ordre d’“arribada” de les factures.",
      confirm: {
        title: 'Canvi de modalitat',
        subtitle: 'Estàs a punt de canviar al mode de descàrregues  ',
        subtitle2: 'manual',
        textTitle: 'Vols continuar amb el canvi?',
        text1: 'Amb la ',
        text2: 'modalitat manual, ',
        text3: 'podràs accedir a la bateria tantes vegades al mes com necessitis, ',
        text4: 'revisar el saldo disponible i aplicar els euros ',
        text45: 'que consideris en cada punt de subministrament.',
        text5: 'Si al final del mes la factura és inferior al descompte aplicat, ',
        text56: 'se’t tornarà el saldo no utilitzat a la bateria. ',
        text6: 'Recorda que ',
        text7: 'hauràs d’accedir-hi periòdicament ',
        text8: 'per assignar el saldo a les diferents factures. ',
      },
      confirmAuto: {
        title: 'Canvi de modalitat',
        subtitle: 'Estàs a punt de canviar al mode de descàrregues  ',
        subtitle2: 'automàtic',
        textTitle: 'Vols continuar amb el canvi?',
        text1: 'En la ',
        text2: 'modalitat automàtica, ',
        text3:
          'les descàrregues es fan descomptant el teu saldo a les factures vinents provant d’aplicar el descompte màxim ',
        text4: 'i reduint l’import de la factura.',
        text5: 'El saldo s’aplicarà en ordre d’“arribada” de les factures.',
      },
    },

    modeBar: {
      title: 'Modalitat de descàrregues',
      change: 'Canvia',
    },

    editModeBar: {
      title: 'Gestió dels punts de subministrament',
      edit: 'Edita',
      titleM: 'Gestió de saldos',
      editM: 'Consulta i assigna',
    },

    assign: 'Assignació de descàrregues',
    how: 'A continuació, pots indicar el saldo que vols descarregar als teus punts de subministrament. ',
    available: 'Saldo disponible',
    save: 'Guarda',
    dischargeBtn: 'Descarta',
    quantitityDischarge: 'Descàrrega en €',

    assignmentBlock: {
      title: 'Tens assignat:',
      subtitle: 'Saldo assignat que es descarregarà en les teves següents factures',
    },

    youHave: 'Tens disponible:',
    totalBalance: 'Saldo acumulat',
    lastUpdate: 'Última actualització',
    limitDate: 'El teu saldo caduca el ',
    accounting_date: "Data d'emissió",
    address: 'Adreça',
    factnum: 'Núm. de factura',
    is_self_consumption: 'Descripció',
    charge: 'Càrrega',
    discharge: 'Descàrrega',

    historical: 'Últims moviments',
    historicalLimitDate:
      "Has donat de baixa el servei el dia {{date}}. Tens 45 dies a partir d’aquesta data per utilitzar el saldo disponible. En el cas que tinguis la configuració automàtica, se't descomptarà de la factura final.",
    infoField: {
      auto: 'En aquesta columna trobaràs informació sobre l’import del moviment.',
      notAuto:
        'Caducat: saldo que ha caducat després de 45 dies de baixa de la bateria o després de 5 anys des que es va generar.',
      charge: 'Càrrega: saldo generat a la bateria.',
      discharge: "Descàrrega: saldo de l’acumulat a la bateria virtual utilitzat a l'adreça triada.",
      rect: 'Factura rectificativa: rectificació de la càrrega a causa de la rectificació de la factura original.',
      s_self_consumption:
        'Autoconsum (per als habitatges que poden acumular energia a la bateria i consumir-la) i Sense autoconsum (per als habitatges que poden bescanviar el saldo de la bateria)',
      concept:
        "En aquesta columna trobaràs informació sobre l’import del moviment.\nCàrrega: saldo generat a la bateria.\nDescàrrega: saldo de l’acumulat a la bateria virtual utilitzat a l'adreça triada.\nCaducat: saldo que ha caducat després de 45 dies de baixa de la bateria o després de 5 anys des que es va generar.\nFactura rectificativa: rectificació de la càrrega a causa de la rectificació de la factura original.\n",
    },

    concept: 'Moviment',

    notFactNum: 'Cicle de facturació en curs',

    total: 'Total',
    quantity: 'Descàrrega',
    auto: 'Autoconsumo',
    notAuto: 'Sin autoconsumo',

    noResultsReason: 'No hay movimientos para mostrar',
    noSupplyResultsReason: 'No hay puntos de suministro para mostrar',

    manual: 'Manual',
    automatic: 'Automàtic',

    supply: 'Nro. de Suministro:',
    manualAssignError: 'El saldo asignado es superior al saldo disponible. Por favor, revisa las asignaciones.',
    warningAutomaticForm: 'Selecciona almenys un punt de subministrament per poder continuar.',
    genericError: 'Se ha producido un error. Por favor, inténtelo de nuevo.',
    errorView: {
      title: 'Ups! Alguna cosa ha fallat',
      subtitle: 'El resum de la teva factura no està disponible en aquests moments.',
      content: 'Torna-ho a intentar més tard',
      loginTitle: 'Identificació errònia. Si us plau, torna-ho a intentar.',
      againLogin: 'Torna a logarte a Area Clients per a accedir a la teva bateria virtual ',
      contactService: 'o contacta amb ',
      customerArea: 'Área Clientes',
      customerService: 'Atenció Client',
    },
  },
}
