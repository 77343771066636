import { format, parse } from 'date-fns'

const ISO_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy'
export const API_DATE_FORMAT = 'yyyy-MM-dd'

export const parseUtcISO = (dateString: string): Date => parse(dateString, ISO_FORMAT, new Date())

export const formatDate = (date: Date | undefined, formatString?: string): string =>
  date ? format(date, formatString || DEFAULT_DATE_FORMAT) : ''
