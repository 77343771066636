export default {
  translation: {
    ok: 'OK',
    yourVirtualBattery: 'Batería virtual',
    psSelection: 'Selección de puntos de subministración',
    psDescription: 'Selecciona os puntos de subministracións que desexas incluír para a descarga. ',
    cancel: 'Cancelar',
    end: 'Finalizar',

    mode: {
      submit: 'Continuar',
      cancel: 'Cancelar',
      subtitle:
        'Dámosche a benvida á túa Batería Virtual. Antes de acceder ao teu saldo e movementos, selecciona a modalidade de descarga. Poderás modificar a túa modalidade en calquera momento.',
      titleManual: 'Modo manual',
      subtitleManual:
        'Con esta modalidade, poderás acceder á túa batería tantas veces como consideres ao mes, revisar o saldo dispoñible e aplicar os € que consideres en cada punto de subministración. Se ao final do mes a factura é inferior ao desconto aplicado, devolveráseche á batería o saldo non utilizado.',
      titleAuto: 'Modo automático',
      subtitleAuto:
        'Nesta modalidade iremos descontando o teu saldo nas vindeiras facturas tratando de aplicar o máximo desconto e deixar a túa factura a 0. O saldo aplicarase en orde de “chegada” das facturas.',
      confirm: {
        title: 'Cambio de modalidade',
        subtitle: 'Vas cambiar ao modo de descargas  ',
        subtitle2: 'manual',
        textTitle: 'Queres continuar co cambio?',
        text1: 'Coa  ',
        text2: 'modalidade manual, ',
        text3: ' poderás acceder á túa batería tantas veces como consideres ao mes, ',
        text4: 'revisar o saldo dispoñible e aplicar os €  ',
        text45: 'que consideres en cada punto de subministración.',
        text5: 'Se ao final do mes a factura é inferior ao desconto aplicado,  ',
        text56: 'devolveráseche o saldo non utilizado á batería.',
        text6: 'Lembra que ',
        text7: 'terás que acceder periodicamente ',
        text8: 'para asignar o saldo ás diferentes facturas.',
      },
      confirmAuto: {
        title: 'Cambio de modalidade',
        subtitle: 'Vas cambiar ao modo de descargas  ',
        subtitle2: 'automático',
        textTitle: 'Queres continuar co cambio?',
        text1: 'Na   ',
        text2: 'modalidade automática, ',
        text3: 'as descargas xéranse descontando o teu saldo nas próximas facturas tratando de aplicar ',
        text4: 'o máximo desconto e reducindo o importe da factura.',
        text5: 'O saldo aplicarase en orde de “chegada” das facturas.',
      },
    },

    modeBar: {
      title: 'Modalidade de descargas',
      change: 'Cambiar',
    },

    editModeBar: {
      title: 'Xestión de puntos de subministracións',
      titleM: 'Xestión de saldos',
      edit: 'Editar',
      editM: 'Ver e asignar',
    },

    assign: 'Asignación de descargas',
    how: 'A continuación, podes indicar o saldo que queres descargar nos teus puntos de subministración.  ',
    available: 'Saldo dispoñible',
    save: 'Gardar',
    dischargeBtn: 'Descartar',
    quantitityDischarge: 'Descarga en €',

    assignmentBlock: {
      title: 'Tes asignado:',
      subtitle: 'Saldo atribuído que descargarase nas túas seguintes facturas',
    },

    youHave: 'Tess dispoñible:',
    totalBalance: 'Saldo acumulado',
    lastUpdate: 'Última actualización',
    limitDate: 'O teu saldo caduca o ',
    accounting_date: 'Data de emisión',
    address: 'Enderezo',
    factnum: 'Nro. de factura',
    is_self_consumption: 'Descrición',
    charge: 'Carga',
    discharge: 'Descarga',
    infoField: {
      auto: 'Nesta columna atoparás información sobre o importe de movemento.',
      notAuto: 'Caducado: saldo que caducou tras 45 días de baixa da batería ou tras 5 anos dende a súa xeración.',
      charge: 'Carga: saldo xerado na batería.',
      discharge: 'Descarga: Saldo utilizado do acumulado na batería virtual, no enderezo elixido.',
      rect: 'Factura rectificadora: rectificación da carga debida á rectificación da factura orixinal',
      s_self_consumption:
        'Autoconsumo (para aquelas vivendas que poden acumular enerxía na batería e consumila) e Sen autoconsumo (para aquelas vivendas que poden trocar o saldo da batería)',
      concept:
        'Nesta columna atoparás información sobre o importe de movemento.\nCarga: saldo xerado na batería.\n Descarga: saldo utilizado do acumulado na batería virtual, no enderezo elixido.\nCaducado: saldo que caducou tras 45 días de baixa da batería ou tras 5 anos dende a súa xeración.\nFactura rectificadora: rectificación da carga debida á rectificación da factura orixinal',
    },

    concept: 'Movemento',

    notFactNum: 'Ciclo de facturación en curso',
    historical: 'Últimos movementos',
    historicalLimitDate:
      'Deches de baixa o servizo o día  {{date}}. Tes 45 días a partir desa data para utilizar o teu saldo dispoñible. No caso no que teñas a configuración automática, descontarase da túa factura final.',

    total: 'Total',
    quantity: 'Descarga',

    auto: 'Autoconsumo',
    notAuto: 'Sin autoconsumo',

    noResultsReason: 'No hay movimientos para mostrar',
    noSupplyResultsReason: 'No hay puntos de suministro para mostrar',

    manual: 'Manual',
    automatic: 'Automático',

    supply: 'Nro. de Suministro:',
    manualAssignError: 'El saldo asignado es superior al saldo disponible. Por favor, revisa las asignaciones.',
    warningAutomaticForm: 'Selecciona polo menos un punto de subministración para poder continuar.',
    genericError: 'Se ha producido un error. Por favor, inténtelo de nuevo.',
    errorView: {
      title: 'Ups! Algo fallou',
      subtitle: 'O resumo da túa factura non está dispoñible nestes intres.',
      content: 'Inténtao máis tarde',
      loginTitle: 'Identificación errada. Por favor, inténtao de novo.',
      againLogin: 'Volve a logarte en Area Clientes para acceder á túa batería virtual ',
      contactService: 'ou contacta con ',
      customerArea: 'Área Clientes',
      customerService: 'Atención Cliente',
    },
  },
}
