import styled from 'styled-components'
import { Typography } from '@mui/material'
import Button from 'components/atoms/button'

import { theme } from 'styles/theme'

export const Container = styled.form``

export const Label = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const LabelTitle = styled(Typography)`
  && {
    font-size: 20px;
    font-weight: 600;
  }
`

export const LabelSubtitle = styled(Typography)`
  && {
    font-size: 14px;
  }
`

export const Footer = styled.div`
  && {
    display: flex;
    justify-content: center;
    margin-top: 33px;
    gap: 10px;
  }
`

export const FormButton = styled(Button)`
  && {
    padding: 15px;
    font-size: 18px;
    max-width: 100%;
    width: 243px;
  }
`
