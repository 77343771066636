import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { ROUTES } from 'config/routes'
import useVirtualBattery from 'hooks/use-battery'
import useHistorical from 'hooks/use-infinite-history'
import UseBatteryMode from 'hooks/use-battery-mode'
import View from './view'

type Props = {
  userId: string
  token: string
}

const ModeBar = ({ userId, token }: Props) => {
  const navigate = useNavigate()
  const { battery } = useVirtualBattery(userId, token)
  const isAutomatic = !!battery?.is_automatic
  const queryClient = useQueryClient()
  const historical = useHistorical(userId, token)

  const batteryData = useVirtualBattery(userId, token)
  const { mutation } = UseBatteryMode(userId, token, {
    onError: () => {
      navigate(ROUTES.error)
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: batteryData.key })
      queryClient.invalidateQueries({ queryKey: historical.key })
      navigate(ROUTES.home)
    },
  })

  const changeMode = (value: any) => {
    navigate(ROUTES.mode, {
      state: {
        value,
      },
    })
  }

  const isLoading = mutation.isLoading || batteryData.isLoading

  const handleClickChange = () => {
    changeMode({
      is_automatic: !isAutomatic,
    })
  }

  const handleClickEdit = () => {
    if (isAutomatic) {
      navigate(`${ROUTES.assign}/automatic`)
    } else {
      navigate(`${ROUTES.assign}/manual`)
    }
  }

  return (
    <View
      onClickChange={handleClickChange}
      onClickEdit={handleClickEdit}
      isAutomatic={isAutomatic}
      isLoading={isLoading}
    />
  )
}

export default ModeBar
