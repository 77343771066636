import { FC } from 'react'
import TextField from '@mui/material/TextField'
import { GridRenderCellParams } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'

import { txtSx } from './styled'
import { formatMoney } from 'utils/numbers'

type Props = {
  params: GridRenderCellParams
  onChangeImport: (event: any, params: GridRenderCellParams) => void
}

const Field: FC<Props> = ({ params, onChangeImport }) => {
  const { t } = useTranslation()

  const onChange = (values: any) => {
    console.log('change')
  }

  return (
    <NumericFormat
      customInput={TextField}
      onValueChange={values => onChange(values)}
      allowNegative={false}
      id='sText'
      label={t('quantitityDischarge')}
      defaultValue={formatMoney(params.value)}
      decimalSeparator=','
      variant='standard'
      inputProps={{ style: { fontWeight: 600, fontSize: 14 } }}
      sx={txtSx}
      onChange={event => onChangeImport(event, params)}
    />
  )
}

export default Field
