import * as React from 'react'
import View from './view'
import * as Types from './types'
import * as S from './styled'

const RadioGroupInput = ({ name, onChange, ...props }: Types.Props) => {
  const handleChange = (e: React.FormEvent<HTMLInputElement>, value: string) => {
    onChange ? onChange({ name, value }) : null
  }
  return <View name={name} onChange={handleChange} {...props} />
}

export default RadioGroupInput
