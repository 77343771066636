import styled, { css } from 'styled-components'
import Container from '@mui/material/Container'
import Chip from 'components/atoms/chip'
import Typography from '@mui/material/Typography'

import { theme } from 'styles/theme'
import { Box } from '@mui/material'

export const BContainer = styled(Container)`
  background: ${theme.colors.white};
  width: '100%';
  justify-content: 'center';
  align-items: 'center';
  padding-bottom: '10px';
  @media (max-width: ${({ theme }) => theme.breakpoints?.values['md']}px) {
    padding-bottom: 63px;
  }
`

export const Item = styled.div`
  display: block;
  border-top: solid 1px ${theme.colors.blueLight};
  padding-top: 16px;
  padding-bottom: 16px;
`

export const Item2 = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  column-gap: 4%;
  row-gap: 24px;
  border-bottom: solid 1px ${theme.colors.blueLight};
  padding-top: 16px;
  padding-bottom: 16px;
`

export const FRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
export const StateRow = styled.div`
  display: flex;
  justify-content: 
  column-gap: 4%;
  row-gap: 24px;
  align-items: center;
  padding-top: 20px;
  justify-content: space-between;
`

export const SRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 4%;
  row-gap: 24px;
  align-items: center;
  padding-top: 20px;
`

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
`

export const TRow = styled.div`
  display: grid;
  grid-template-columns: 100%;
  row-gap: 24px;
  align-items: center;
  padding-top: 20px;
`

interface AutoChipProps {
  isAuto?: boolean | undefined
  styleVariant?: string | undefined
}

export const AutoChip = styled(Chip)<AutoChipProps>`
  && {
    padding-left: 12px;

    background-color: ${({ isAuto }) => (isAuto ? theme.colors.green : theme.colors.blue)};
  }
`

export const ItemField = styled.div`
  display: block;
  align-items: center;
  margin-left: ${({ hasMarginLeft }: { hasMarginLeft?: boolean }) => (hasMarginLeft ? '45px' : '0px')};
  max-width: 100%;
`

export const Label = styled(Typography)``

export const textHighLighted = css`
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
`

interface TextProps {
  highlighted?: boolean | undefined
}

export const Text = styled(Typography)<TextProps>`
  && {
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${({ highlighted }) => (highlighted ? textHighLighted : '')}
  }
`

export const AddressText = styled(Typography)<TextProps>`
  && {
    overflow: hidden;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${({ highlighted }) => (highlighted ? textHighLighted : '')}
  }
`

export const Title = styled(Typography)``

export const Total = styled(Typography)`
  && {
    font-weight: 600;
    color: ${theme.colors.orange};
  }
`

export const buttonStyle = {
  width: '40%',
  maxWidth: 200,
}

export const ButtonBox = styled(Box)`
  && {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    gap: 16px;
    padding: 16px;
    @media (max-width: ${({ theme }) => theme.breakpoints?.values['md']}px) {
      position: fixed;
      bottom: 0px;
      left: 0px;
      width: 100vw;
      margin-top: 0px;
      padding: 16px;
      box-shadow: ${theme.shadows.shadow1};
      background-color: ${theme.colors.white};
    }
  }
`

export const txtSx = {
  marginTop: '12px',
  color: theme.colors.blue,
  backgroundColor: 'rgba(0, 69, 113, 0.05)',
  input: { color: theme.colors.blue },
  border: { color: theme.colors.blue },
}
