import { ChangeEvent, FC, useCallback, useState } from 'react'
import Checkbox from '@mui/material/Checkbox'
import { GridRenderCellParams } from '@mui/x-data-grid'
import { SxProps, Theme } from '@mui/material'
import { CheckIcon, Unchecked } from 'assets/icons'

export type ParamsProps = {
  supply: string
  value?: boolean
}

type Props = {
  params: GridRenderCellParams | ParamsProps
  sx?: SxProps<Theme> | undefined
  boxSx?: SxProps<Theme> | undefined
  onChangeCheck: (event: any, params: GridRenderCellParams | ParamsProps, value: boolean) => void
}

const CheckCells: FC<Props> = ({ params, onChangeCheck, sx = {}, boxSx = {} }) => {
  const [isChecked, setIsChecked] = useState(params.value)

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsChecked(event?.target?.checked)
      onChangeCheck(event, params, event?.target?.checked)
    },
    [onChangeCheck, params]
  )

  return (
    <>
      <Checkbox checked={isChecked} checkedIcon={<CheckIcon />} onChange={onChange} icon={<Unchecked />} />
    </>
  )
}

export default CheckCells
