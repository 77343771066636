import { FC, useCallback } from 'react'
import Typography from '@mui/material/Typography'
import { GridRenderCellParams } from '@mui/x-data-grid'
import Tooltip from 'components/atoms/tooltip'
import { postIframeHeightEvent } from 'utils/functions'
import { SxProps, Theme } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { formatDate, parseUtcISO } from 'utils/dates'
import { formatMoney } from 'utils/numbers'

import { CustomBox, TBox } from './styled'

type Props = {
  params: GridRenderCellParams
  highlighted?: boolean
  isDate?: boolean
  prefix?: string | null
  isImport?: boolean
  title?: string | null
  sx?: SxProps<Theme> | undefined
  boxSx?: SxProps<Theme> | undefined
  replaceNull?: string | null
  tooltip?: string
}

const Cells: FC<Props> = ({
  params,
  highlighted,
  isDate,
  prefix,
  isImport = false,
  title,
  sx = {},
  boxSx = {},
  replaceNull,
  tooltip,
}) => {
  const { t } = useTranslation()

  const getState = useCallback(
    (val: boolean) => {
      return val ? t('active') : t('inactive')
    },
    [t]
  )

  const renderText = useCallback(() => {
    let value
    if (isImport) {
      value = formatMoney(params.value?.toString() || '0.00')
    } else if (typeof params.value === 'boolean') {
      value = getState(params.value)
    } else {
      value = replaceNull && !params.value ? replaceNull : params.value
    }

    postIframeHeightEvent(false)

    return (
      <CustomBox>
        <TBox sx={boxSx}>
          <Tooltip title={tooltip}>
            <Typography
              variant='body1'
              sx={{
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                alignSelf: 'center',
                fontWeight: highlighted ? 600 : 400,
                ...sx,
              }}
              noWrap
            >
              {`${prefix || ''}${value}`}
            </Typography>
          </Tooltip>

          {title ? (
            <Typography
              variant='body1'
              sx={{
                fontSize: '11px',
                ...sx,
              }}
              noWrap
            >
              {title}
            </Typography>
          ) : null}
        </TBox>
      </CustomBox>
    )
  }, [boxSx, getState, highlighted, isImport, params.value, prefix, sx, title])

  const renderDate = useCallback(() => {
    let dateString = '-'
    if (params.value) {
      const formDate = parseUtcISO(params.value.toString())
      dateString = `${formatDate(formDate)}`
    }

    return (
      <CustomBox>
        <Tooltip title={tooltip}>
          <Typography variant='body1' className='defaultDateText'>
            {dateString}
          </Typography>
        </Tooltip>
      </CustomBox>
    )
  }, [params.value])

  return isDate ? renderDate() : renderText()
}

export default Cells
