import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { ROUTES } from 'config/routes'
import { postIframeHeightEvent, getUrlParam } from 'utils/functions'
import View from './view'
import useAccessData from 'hooks/use-access-data'
import useBattery from 'hooks/use-battery'
import useInfiniteHistory from 'hooks/use-infinite-history'
import { changeLanguage } from 'i18next'

const Access = () => {
  const { token } = useParams()

  const [minTimeReady, setMinTimeReady] = useState(false)
  const dni = getUrlParam('userid') || undefined
  const lng = getUrlParam('lng') || undefined

  const { getData, setData } = useAccessData()
  const data = getData()
  const batteryData = useBattery(dni, token)
  const historyData = useInfiniteHistory(dni, token)

  const navigate = useNavigate()

  useEffect(() => {
    const newToken = token || data?.token
    const newData =
      token && dni
        ? {
            token,
            dni,
            lng,
          }
        : { ...data }

    newData.token = newToken
    newData.dni = dni
    newData.lng = lng
    setData(newData)
    changeLanguage(lng)
    postIframeHeightEvent(true)
    setTimeout(() => {
      setMinTimeReady(true)
    }, 1800)
  }, [data, dni, lng, navigate, setData, token])

  useEffect(() => {
    const isReady = batteryData.isFetched && historyData.isFetched && minTimeReady
    const hasErrors = batteryData.isError || historyData.isError
    const hasMode = batteryData?.battery?.is_automatic !== null && batteryData?.battery?.is_automatic !== undefined

    if (isReady && !hasErrors) {
      navigate(hasMode ? ROUTES.home : ROUTES.mode)
    }
    if (isReady && hasErrors) {
      navigate(ROUTES.error)
    }
  }, [batteryData, historyData, minTimeReady])

  return <View />
}

export default Access
