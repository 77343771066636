type AccessDataType = {
  token?: string
}

const useAccessData = () => {
  const getData = () => JSON.parse(localStorage.getItem('accessData') || '{}')
  const setData = (data: AccessDataType): void => localStorage.setItem('accessData', JSON.stringify(data))
  return { getData, setData }
}

export default useAccessData
