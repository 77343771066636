import { useEffect } from 'react'
import { changeLanguage } from 'i18next'

import Battery from 'components/organisms/battery'
import useAccessData from 'hooks/use-access-data'

const AppView = () => {
  const { getData } = useAccessData()

  const { dni, token, lng } = getData()

  useEffect(() => {
    try {
      changeLanguage(lng)
    } catch (e) {
      console.log('Error')
    }
  }, [lng])

  return <Battery userId={dni} token={token} />
}

export default AppView
