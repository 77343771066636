import styled from 'styled-components/macro'

export const Spinner = styled.div`
  max-height: 100vh;
  bottom: 0;

  background: ${({ theme }) => theme.palette.common.white};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 9999;
  min-height: 500px;
  height: ${window.screen.height * 0.75};
  max-height: ${window.screen.height * 0.75};
`

export const SpinnerImage = styled.img`
  width: 100%;
  max-width: 250px;
  @media (max-width: ${({ theme }) => theme.breakpoints.values['md']}px) {
    max-width: 100px;
    max-height: 100px;
  }
`
