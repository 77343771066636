import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import { fetchVirtualBattery } from 'api'
import BatteryModel, { BatteryTypesResponse } from 'model/interfaces/battery'
import { AxiosResponse } from 'axios'

type BatteryQueryKey = readonly ['battery', { userId?: string; token?: string }]

const useVirtualBattery = (userId?: string, token?: string) => {
  const { data, ...response } = useQuery<
    AxiosResponse<BatteryTypesResponse>,
    Error,
    BatteryTypesResponse,
    BatteryQueryKey
  >(['battery', { userId, token }], fetchVirtualBattery, {
    enabled: !!userId,
  })

  const battery: BatteryModel | undefined = useMemo(() => {
    return data?.data
  }, [data?.data])

  return { battery, key: ['battery', { userId, token }], ...response }
}

export default useVirtualBattery
