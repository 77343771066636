export const theme = {
  colors: {
    black: '#000000',
    blue: '#004571',
    orange: '#E57200',
    white: '#FFFFFF',
    lightGray: '#FAFAFA',
    green: '#6ECE72',
    blueLight: '#E5ECF0',
    greyLight: '#F2F6F8',
    warning: '#FDF1E6',
  },

  shadows: {
    shadow1: '0px 3px 20px rgba(12, 17, 34, 0.1)',
  },

  sizes: {
    header: '60px',
    sideMenu: '240px',
  },
}
