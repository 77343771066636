import styled from 'styled-components'
import Typography from '@mui/material/Typography'
import ModeForm from 'components/molecules/mode-form'

export const Container = styled.div``

export const Subtitle = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: 300;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const Form = styled(ModeForm)`
  padding: 40px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoints?.values['md']}px) {
    padding: 33px 0px;
  }
`

export const ContainerConfirm = styled.div`
  margin-top: 56px;
`
