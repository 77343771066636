import { useMutation } from '@tanstack/react-query'

import { fetchPutMode } from 'api'

type MutationOptions = {
  is_automatic: boolean
}

const useVirtualBatteryMode = (userId?: string, token?: string, options?: any) => {
  const mutation = useMutation({
    mutationFn: ({ ...props }: MutationOptions) => fetchPutMode({ ...props }),
    ...options,
  })

  return { mutation }
}

export default useVirtualBatteryMode
