import { DataGrid } from '@mui/x-data-grid'
import { styled } from '@mui/material/styles'
import TooltipComp from 'components/atoms/tooltip'
import HistoricalList from 'components/molecules/historical-list'
import ContainerComp from 'components/atoms/container'
import { Info } from 'assets/icons'

import { theme } from 'styles/theme'
import { Box, Typography } from '@mui/material'

export const StyledDataGrid = styled(DataGrid)(({ theme: muiTheme }) => ({
  border: 0,
  color: muiTheme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  '& .MuiDataGrid-footerContainer': {
    display: 'none',
  },
  '& .MuiDataGrid-virtualScroller': {
    overflow: 'hidden',
    minHeight: '70px',
  },
  '& .MuiDataGrid-main': {
    background: theme.colors.white,
    borderRadius: 10,
    marginBottom: '20px',
    borderLeft: `1px solid ${muiTheme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
    borderRight: `1px solid ${muiTheme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
    borderBottom: `1px solid ${muiTheme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,

    '.defaultHeader, .defaultTextColumn, .defaultBoldHeader, .defaultBoldTextColumn': {
      color: theme.colors.blue,
      paddingTop: '36px',
      paddingBottom: '36px',
      textAlign: 'center',

      a: {
        color: theme.colors.blue,
        textDecoration: 'none',
      },

      '&.not-overflow': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },

    '.defaultBoldHeader, .defaultBoldTextColumn': {
      fontWeight: 600,
    },
    '.defaultDateText': {
      color: theme.colors.blue,
      paddingTop: '36px',
      paddingBottom: '36px',
      fontWeight: 600,
      textAlign: 'center',
      a: {
        color: theme.colors.blue,
        textDecoration: 'none',
      },

      '&.not-overflow': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
  '& .MuiDataGrid-columnsContainer': {
    backgroundColor: muiTheme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.colors.blueLight,
    borderBottom: `0px solid ${muiTheme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
    borderTop: `0px solid ${muiTheme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
  },
  '& .MuiDataGrid-columnHeader': {},
  '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${muiTheme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
    borderTop: `0px solid ${muiTheme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
  },
  '& .MuiDataGrid-cell': {
    color: muiTheme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
  },
  '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
}))

export const AutoTypography = styled(Typography)({
  marginTop: '10px',
  textAlign: 'center',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  color: theme.colors.white,
  fontWeight: 600,
  paddingLeft: '12px',
  paddingRight: '12px',
  borderRadius: '25px',
  width: '120px',
  height: '30px',
  background: theme.colors.green,
  fontSize: '12px',
  marginBottom: '10px',
})

export const CustomBox = styled(Box)({
  flex: 1,
  display: 'flex',
  paddingTop: '36px',
  paddingBottom: '36px',
  alignItems: 'center',
  justifyContent: 'center',
})

export const TBox = styled(Box)({
  flex: 1,
  display: 'flex',
  paddingTop: '36px',
  paddingBottom: '36px',
  alignItems: 'center',
  justifyContent: 'center',
})

export const StyledSmList = styled(HistoricalList)``

export const Container = styled(ContainerComp)`
  padding: 0px;
`
export const NotAutoTypography = styled(AutoTypography)({
  background: theme.colors.blue,
})

export const InfoIcon = styled(Info)`
  && {
    width: 17px;
    margin-left: 5px;
  }
`

export const Tooltip = styled(TooltipComp)``

export const cellSx = { width: '150px' }

export const boxSt = { display: { xs: 'block', sm: 'block', md: 'none' } }

export const boxDiv = { display: { xs: 'none', sm: 'none', md: 'block' } }
