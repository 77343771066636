import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { SupplyPointsToSave } from 'model/interfaces/supply-points'
import UseBatteryMode from 'hooks/use-battery-mode'
import { postIframeHeightEvent } from 'utils/functions'
import { ROUTES } from 'config/routes'
import useSaveSupply from 'hooks/use-save-supply'
import useSupplyPoints from 'hooks/use-supply-points'
import useVirtualBattery from 'hooks/use-battery'
import useHistorical from 'hooks/use-infinite-history'
import AutomaticResponsiveView from 'components/organisms/supply-points-responsive/automatic-view'
import ManualResponsiveView from 'components/organisms/supply-points-responsive/manual-view'

import AutomaticView from './automatic-view'
import ManualView from './manual-view'
import { boxDiv, boxSt } from './styled'

type Props = {
  userId: string
  token: string
  isManual: boolean
  submitMode?: boolean
}

const SupplyPoints = ({ userId, token, isManual, submitMode }: Props) => {
  const { supplyPoints, isFetching, total, key } = useSupplyPoints(userId, token)
  const [errors, setErrors] = useState<string[]>([])
  const navigate = useNavigate()
  const historical = useHistorical(userId, token)
  const batteryData = useVirtualBattery(userId, token)
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const handleSuccess = () => {
    queryClient.invalidateQueries({ queryKey: key })
    navigate(ROUTES.home)
  }

  const handleError = () => {
    setErrors([t('genericError')])
  }

  const { saveSupplyPoints, isSaving } = useSaveSupply(handleSuccess, handleError)

  const { mutation: batteryModeMutation } = UseBatteryMode(userId, token, {
    onError: () => {
      navigate(ROUTES.error)
    },

    onSuccess: (props: any, { is_automatic }: { is_automatic: boolean }) => {
      queryClient.invalidateQueries({ queryKey: batteryData.key })
      queryClient.invalidateQueries({ queryKey: historical.key })

      navigate(`${ROUTES.assign}${is_automatic ? '/automatic' : '/manual'}`)
    },
  })

  const validate = (values: SupplyPointsToSave[]) => {
    const errors = []
    let inputTotal = 0.0
    Object.keys(values).forEach((item: any) => {
      inputTotal += parseFloat(values[item].quantity.toString())
    })

    if ((total || 0) < inputTotal) {
      errors.push(t('manualAssignError'))
    }

    return errors
  }

  useEffect(() => {
    postIframeHeightEvent(false)
  })

  const submitChangeMode = (value: any, supplyPointsList: SupplyPointsToSave[]) => {
    batteryModeMutation.mutate(
      {
        ...value,
      },
      {
        onSuccess: () => {
          saveSupplyPoints(supplyPointsList)
        },
      }
    )
  }

  const handleOnSave = useCallback(
    (supplyPointsList: SupplyPointsToSave[]) => {
      console.log('SUMINISTRO', supplyPointsList)
      const formErrors = validate(supplyPointsList)
      if (formErrors.length > 0) {
        setErrors(formErrors)
      } else {
        const value = {
          is_automatic: !isManual,
        }
        submitMode ? submitChangeMode(value, supplyPointsList) : saveSupplyPoints(supplyPointsList)
      }
    },
    [saveSupplyPoints, submitChangeMode, isManual, submitMode]
  )

  const handleOnSaveAutomatic = useCallback(
    (supplyPointsList: SupplyPointsToSave[]) => {
      const value = {
        is_automatic: !isManual,
      }
      submitMode ? submitChangeMode(value, supplyPointsList) : saveSupplyPoints(supplyPointsList)
    },
    [saveSupplyPoints, isManual, submitMode]
  )

  const handleDischarge = useCallback(() => {
    navigate(ROUTES.home)
  }, [navigate])

  const handleCancel = useCallback(() => {
    navigate(ROUTES.home)
  }, [navigate])

  return isManual ? (
    <>
      <Box component='div' sx={boxSt}>
        <ManualResponsiveView
          supplyPoints={supplyPoints}
          onSave={handleOnSave}
          onCancel={handleDischarge}
          isSaving={isSaving}
          total={total}
          errors={errors}
        />
      </Box>
      <Box component='div' sx={boxDiv}>
        <ManualView
          supplyPoints={supplyPoints}
          onSave={handleOnSave}
          onCancel={handleDischarge}
          isSaving={isSaving}
          total={total}
          errors={errors}
        />
      </Box>
    </>
  ) : (
    <>
      <Box component='div' sx={boxSt}>
        <AutomaticResponsiveView
          supplyPoints={supplyPoints}
          onSave={handleOnSaveAutomatic}
          onCancel={handleCancel}
          isFetching={isFetching}
          isSaving={isSaving}
          errors={errors}
        />
      </Box>
      <Box component='div' sx={boxDiv}>
        <AutomaticView
          supplyPoints={supplyPoints}
          onSave={handleOnSaveAutomatic}
          onCancel={handleCancel}
          isFetching={isFetching}
          isSaving={isSaving}
          errors={errors}
        />
      </Box>
    </>
  )
}

export default SupplyPoints
