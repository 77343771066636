import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

import { postSupplyPoints } from 'api'
import { SupplyPointsToSave } from 'model/interfaces/supply-points'
import { useCallback } from 'react'

const useSaveSupply = (onSuccess: (data: AxiosResponse<any, any>) => void, onError: (error: unknown) => void) => {
  const mutation = useMutation({
    mutationFn: (data: SupplyPointsToSave) => {
      return postSupplyPoints(data)
    },
    onError: (error, variables, context) => {
      onError(error)
    },
    onSuccess: (data, variables, context) => {
      onSuccess(data)
    },
  })

  const saveSupplyPoints = useCallback(
    (data: any) => {
      mutation.mutate(data)
    },
    [mutation]
  )

  return { saveSupplyPoints, isSaving: mutation.isLoading }
}

export default useSaveSupply
