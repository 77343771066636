import { FC } from 'react'
import { Typography } from '@mui/material'

import Button from 'components/atoms/button'
import Icon from 'components/atoms/icon'
import { ArrowLeft, ArrowRight } from 'assets/icons'
import { DEFAULT_PAGE_SIZE, TOTAL_PAGES_TO_SHOW } from 'utils/constants'

import * as S from './styled'

interface Props {
  page: number
  hasNextPage?: boolean
  onPage: (page: number) => void
  onPrevPage: () => void
  onNextPage: () => void
  totalPages?: number
}

const PaginationButtons: FC<Props> = ({ page, hasNextPage, onPrevPage, onNextPage, onPage, totalPages = 10 }) => {
  // const pagesTotal = Math.ceil(totalPages / DEFAULT_PAGE_SIZE)
  return (
    <S.Container>
      <Button
        color='white'
        variant='text'
        startIcon={<Icon Source={ArrowLeft} color={'white'} width={'16px'} height={'16px'} />}
        onClick={onPrevPage}
      />
      <PagesNumber total={totalPages} pageSize={DEFAULT_PAGE_SIZE} page={page} onPage={onPage} />
      <Button
        color='white'
        variant='text'
        endIcon={<Icon Source={ArrowRight} color={'white'} width={'16px'} height={'16px'} />}
        disabled={!hasNextPage}
        onClick={onNextPage}
      />
    </S.Container>
  )
}

type PagesNumberProps = {
  total: number
  pageSize: number
  page: number
  onPage: (page: number) => void
}

const PagesNumber: FC<PagesNumberProps> = ({ total = 100, page, onPage }) => {
  const pages = []

  const startWith = 1

  for (let i = startWith; i <= total && i <= TOTAL_PAGES_TO_SHOW + startWith; i++) {
    pages[i] = i
  }

  const onShow = (element: number) => {
    onPage(element)
  }

  return (
    <S.PagesContainer>
      {pages?.map(element => (
        <Typography
          onClick={() => onShow(element)}
          variant='body2'
          key={element}
          sx={element === page ? S.pagesStyleBold : S.pagesStyle}
        >
          {element}
        </Typography>
      ))}
    </S.PagesContainer>
  )
}

export default PaginationButtons
