import { useTranslation } from 'react-i18next'
import * as Types from './types'
import img from 'assets/images/battery_img.svg'

import { TitleBox, CenterBox, FBox, BatteryImage, TitleTypography } from './styled'

const Title = ({ hasImage }: Types.Props) => {
  const { t } = useTranslation()
  return (
    <CenterBox>
      <TitleBox>
        <FBox>
          <TitleTypography variant='h2'>{t(`yourVirtualBattery`)}</TitleTypography>
        </FBox>
      </TitleBox>
      {hasImage && <BatteryImage src={img} />}
    </CenterBox>
  )
}

Title.defaultProps = {
  hasImage: true,
}

export default Title
