import styled from 'styled-components'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'

import { theme } from 'styles/theme'

export const BContainer = styled(Container)({
  background: theme.colors.white,
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '10px',
})

export const AvailableBox = styled(Box)({
  borderRadius: '20px',
  padding: '24px',
  width: '100%',
  paddingLeft: '20px',
  paddingRight: '20px',
  marginBottom: '24px',
})

export const AvailableBoxGrey = styled(Box)`
  && {
    border-radius: 20px;
    padding: 24px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 8px;
    border: none;
    background-color: ${theme.colors.greyLight};
  }
`

export const CenterBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const CBox = styled(Box)({
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
})
export const AvailableCenterBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
})

export const CenterTypography = styled(Typography)({
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
})

export const LimitDateText = styled(Typography)`
  && {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: end;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 400;
  }
`

export const WarningIcon = styled(WarningAmberRoundedIcon)`
  color: ${theme.colors.orange};
  margin-right: 3px;
`

export const OrangeTypography = styled(Typography)`
  && {
    font-weight: 600;
    color: ${theme.colors.orange};
    @media (max-width: ${({ theme }) => theme.breakpoints?.values['md']}px) {
      font-size: 30px;
    }
  }
`

export const BoldTypography = styled(Typography)`
  && {
    font-weight: 600;
    color: ${theme.colors.blue};
    @media (max-width: ${({ theme }) => theme.breakpoints?.values['md']}px) {
      font-size: 30px;
    }
  }
`

export const borderStyle = { border: 2, borderColor: theme.colors.blueLight }

export const marginStyle = { ml: 2, mb: 4 }
