import { PopperProps, ClickAwayListener } from '@mui/material'
import TooltipComp from '@mui/material/Tooltip'

type Props = {
  className?: string
  children: React.ReactElement
  title?: React.ReactNode
  arrow?: boolean
  PopperProps?: Partial<PopperProps>
  onClose: (event: MouseEvent | TouchEvent) => void
  open?: boolean
  disableFocusListener?: boolean
  disableHoverListener?: boolean
  disableTouchListener?: boolean
  isSm?: boolean
}

const Tooltip = ({ className, children, title, arrow, onClose, isSm, open, ...rest }: Props) => {
  if (isSm) {
    return (
      <TooltipComp
        {...rest}
        title={
          <ClickAwayListener onClickAway={onClose}>
            <div>{title}</div>
          </ClickAwayListener>
        }
        classes={{ popper: className }}
        arrow={arrow}
        open={open}
      >
        {children}
      </TooltipComp>
    )
  }
  return (
    <TooltipComp {...rest} title={title} classes={{ popper: className }} arrow={arrow} open={open}>
      {children}
    </TooltipComp>
  )
}
export default Tooltip
