import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import TooltipComp from 'components/atoms/tooltip'
import HistoricalList from 'components/molecules/historical-list'

import { Box } from '@mui/material'

export const CustomBox = styled(Box)({
  flex: 1,
  display: 'flex',
  paddingTop: '36px',
  paddingBottom: '36px',
  alignItems: 'center',
  justifyContent: 'center',
})

export const TBox = styled(Box)({
  flex: 1,
  display: 'grid',
  justifyContent: 'center',
})

export const StyledSmList = styled(HistoricalList)``

export const Tooltip = styled(TooltipComp)``

export const desc = {
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
}
