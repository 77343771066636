import axios, { AxiosResponse, AxiosError } from 'axios'

import { BASE_URL } from 'config/api'
import { DEFAULT_PAGE_SIZE } from 'utils/constants'

import * as logger from './logger'
import { SupplyPointsToSave } from 'model/interfaces/supply-points'

export const aInstance = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-type': 'application/json' },
})

aInstance.interceptors.request.use(config => {
  const { dni, token } = JSON.parse(localStorage.getItem('accessData') || '{}')
  config.headers.token = token
  config.headers.nif = dni

  return config
})

aInstance.interceptors.response.use(
  (res: AxiosResponse) => {
    logger.logResponse(res.config?.method, res.config?.url, res)
    return res
  },
  (e: AxiosError) => {
    logger.logError(e)
    return Promise.reject(e)
  }
)

export const fetchVirtualBattery = (): Promise<AxiosResponse> => {
  const url = `/user?`
  return aInstance.get(url)
}

export const fetchHistorical = (pageNumber = 0): Promise<AxiosResponse> => {
  const url = `/history?&limit=${DEFAULT_PAGE_SIZE}&page=${pageNumber}`
  return aInstance.get(url)
}

export const fetchSupplyPoints = (): Promise<AxiosResponse> => {
  const url = `/assign?`
  return aInstance.get(url)
}

export const fetchPutMode = (options?: { is_automatic: boolean }): Promise<AxiosResponse> => {
  const url = `/assign?`
  return aInstance.put(url, options)
}

export const postSupplyPoints = (data: SupplyPointsToSave): Promise<AxiosResponse> => {
  const url = `/assign?`
  return aInstance.post(url, data)
}
