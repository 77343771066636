import { useTranslation } from 'react-i18next'

import SupplyPoints from 'components/organisms/supply-points'
import useAccessData from 'hooks/use-access-data'

import { TitleBox, TitleTypography, SmallTypography, TitleContainer } from './styled'
import { useParams, useLocation } from 'react-router-dom'
import { useMemo } from 'react'

const SupplyPointsView = () => {
  const { type } = useParams()
  const { state: locationState } = useLocation()
  const { t } = useTranslation()
  const { getData } = useAccessData()
  const { dni, token } = getData()

  const isManual = useMemo(() => {
    return type === 'manual'
  }, [type])

  return (
    <>
      <TitleContainer>
        <TitleBox>
          <TitleTypography variant='h3' sx={{ fontWeight: 250 }}>
            {isManual ? t(`assign`) : t(`psSelection`)}
          </TitleTypography>
          <SmallTypography variant='body1'>{isManual ? t(`how`) : t(`psDescription`)}</SmallTypography>
        </TitleBox>
      </TitleContainer>
      <SupplyPoints userId={dni} token={token} isManual={isManual} submitMode={locationState?.submitMode} />
    </>
  )
}

export default SupplyPointsView
