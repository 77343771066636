import { useTranslation } from 'react-i18next'
import { ModeForm } from 'model/interfaces/battery'
import Title from 'components/molecules/title'
import ModeConfirmManual from 'components/molecules/mode-confirm-manual'

import * as S from './styled'

type Props = {
  onSubmit: (value: ModeForm) => void
  isLoading: boolean
  defaultValue: ModeForm
  confirmation: ModeForm | null
  onSuccess: () => void
  onCancel: () => void
  onCloseMode?: () => void
}

const View = ({ onSubmit, isLoading, defaultValue, confirmation, onSuccess, onCancel, onCloseMode }: Props) => {
  const { t } = useTranslation()

  if (confirmation)
    return (
      <S.ContainerConfirm>
        <ModeConfirmManual
          onSuccess={onSuccess}
          onCancel={onCancel}
          isAutomatic={confirmation?.is_automatic}
          isLoading={isLoading}
        />
      </S.ContainerConfirm>
    )

  return (
    <S.Container>
      <Title hasImage={false} />
      <S.Header>
        <S.Subtitle>{t('mode.subtitle')}</S.Subtitle>
      </S.Header>
      <S.Form onSubmit={onSubmit} isLoading={isLoading} defaultValue={defaultValue} onClose={onCloseMode} />
    </S.Container>
  )
}

export default View
