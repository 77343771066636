import { Outlet } from 'react-router-dom'
import * as S from './styled'

const RoutesView = () => (
  <S.Container>
    <Outlet />
  </S.Container>
)

export default RoutesView
