import { useState } from 'react'
import View from './view'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import UseBatteryMode from 'hooks/use-battery-mode'
import useVirtualBattery from 'hooks/use-battery'
import useHistorical from 'hooks/use-infinite-history'
import { ROUTES } from 'config/routes'

type Props = {
  userId: string
  token: string
  defaultValue?: { is_automatic: boolean }
}

const ModeSelection = ({ defaultValue, userId, token }: Props) => {
  const navigate = useNavigate()

  const [confirmation, setConfirmation] = useState<any>(defaultValue || null)
  const queryClient = useQueryClient()
  const historical = useHistorical(userId, token)

  const batteryData = useVirtualBattery(userId, token)

  const hasMode = batteryData?.battery?.is_automatic !== null && batteryData?.battery?.is_automatic !== undefined

  const { mutation } = UseBatteryMode(userId, token, {
    onError: () => {
      navigate(ROUTES.error)
    },

    onSuccess: (props: any, { is_automatic }: { is_automatic: boolean }) => {
      queryClient.invalidateQueries({ queryKey: batteryData.key })
      queryClient.invalidateQueries({ queryKey: historical.key })

      navigate(`${ROUTES.assign}${is_automatic ? '/automatic' : '/manual'}`)
    },
  })

  const handleSubmit = (value: any) => {
    if (hasMode) {
      setConfirmation(value)
    } else {
      mutation.mutate({
        ...value,
      })
    }
  }

  const handleSuccess = () => {
    navigate(`${ROUTES.assign}${!value?.is_automatic ? '/automatic' : '/manual'}`, { state: { submitMode: true } })
  }

  const handleCancel = () => {
    if (defaultValue) {
      navigate(ROUTES.home)
    } else {
      setConfirmation(null)
    }
  }

  const handleCloseMode = () => {
    navigate(ROUTES.home)
  }

  const isLoading = mutation.isLoading || batteryData.isLoading

  const value = {
    is_automatic: hasMode ? batteryData?.battery?.is_automatic : true,
  }
  if (!batteryData?.battery) return null
  return (
    <View
      defaultValue={value}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      confirmation={confirmation}
      onCloseMode={hasMode ? handleCloseMode : undefined}
      onSuccess={handleSuccess}
      onCancel={handleCancel}
    />
  )
}

export default ModeSelection
